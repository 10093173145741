
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import TextField from '@/components/TextField.vue';
import Title2 from '@/components/Title2.vue';
import NationalityField from '@/components/NationalityField.vue';
import GenderField from '@/components/GenderField.vue';
import { Manager } from '@/interfaces/card';
import { Gender } from '@/interfaces/enums';
import { LabelledValue } from '@/interfaces/utils';
import { fieldRules } from '@/utils';

const talentCardsModule = namespace('talentCards');
const mainModule = namespace('main');

@Component({
  components: {
  TextField,
  Title2,
  GenderField,
  NationalityField,
  },
  })
export default class AboutManager extends Vue {
  @Prop() manager!: Manager;
  @Prop() title!: string;
  @Prop() id!: string;
  @talentCardsModule.Getter('managers') managers!: Manager[];
  @talentCardsModule.Action('getManagers') getManagers: any;
  @mainModule.Getter('nationalities') nationalities!: LabelledValue[];

  updateField(key: string, newValue: Gender | string[]) {
    Vue.set(this.manager, key, newValue);
    this.getManagers();
    this.$emit('handleSave');
  }

  handleChange(value: any, field: string) {
    if (_.isPlainObject(value)) {
      Vue.set(this.manager, 'gender', value.gender);
      Vue.set(this.manager, 'nationality', value.nationality);
      Vue.set(this.manager, 'name', value.name);
      Vue.set(this.manager, 'surname', value.surname);
    } else {
      let newValue = value;
      if (field === 'name' || field === 'surname') {
        if(value === null){
          newValue = '';
        } else if (value.length > 31){
          newValue = value.slice(0, 30);
        }
      }
      Vue.set(this.manager, field, newValue);
      this.getManagers();
    }

    this.$emit('handleSave');
  }

  getNationalityLabel(code: string) {
    const nationality = this.nationalities.find((item) => (code === item.value));
    return nationality ? nationality.label : '';
  }


}
