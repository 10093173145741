
import Title2 from '@/components/Title2.vue';
import SelectField from '@/components/SelectField.vue'
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LabelledValue } from '@/interfaces/utils';
import { namespace } from 'vuex-class';
import { SimplyUser } from '@/interfaces/user';
import { SimplyCard } from '@/interfaces/card';

const usersModule = namespace('users');
const talentCardsModule = namespace('talentCards');

@Component(
  {
    components: {
      Title2,
      SelectField,
    }
  }
)

export default class ChangeDialog extends Vue {
  @usersModule.Getter('allUsers') allUsersList!: SimplyUser[];
  @usersModule.Action('getUsers') getUsers: any;
  @talentCardsModule.Action('updateTalentCards') updateTalentCards: any;

  @Prop() selectedCards!: SimplyCard[];

  userId: string = '';

  created() {
    this.getUsers();
  }

  closeDialog() {
    this.userId = '';
    this.$emit('closeDialog');
  }

  saveChange() {
    this.selectedCards.map((card) => {
      card.userId = this.userId;
    });
    this.updateTalentCards(this.selectedCards);
    this.closeDialog();
  }

  get userLabels(): LabelledValue[] {
    return this.allUsersList.map((user) => {
      return {
        label: (user.name !== '' && user.name !== null &&
          user.surname !== '' && user.surname !== null
        ) ? `${user.name} ${user.surname}` : user.email,
        value: user.id,
      } as LabelledValue;
    })
  }
}
