
import {Component, Prop, Vue} from 'vue-property-decorator';
import Banner from '@/components/Banner.vue';
import {namespace} from 'vuex-class';
import Home from '../talentApp/homePage/Home.vue';
import {SimplyCard} from '@/interfaces/card';
import {User} from '@/interfaces/user';
import {Status} from '@/interfaces/enums';
import TextField from '@/components/TextField.vue';
import _ from 'lodash';
import ChangeDialog from '@/views/changeOwnership/ChangeDialog.vue';
import Cards from '../talentApp/homePage/Cards.vue';

const talentCardsModule = namespace('talentCards');
const authModule = namespace('auth');

@Component(
  {
    components: {
      Home,
      Banner,
      TextField,
      ChangeDialog,
      Cards
    }
  }
)

export default class ChangePage extends Vue {
  @talentCardsModule.Action('getTalentCards') getTalentCards: any;
  @talentCardsModule.Getter('allCards') allCards!: SimplyCard[];
  @authModule.Getter('currentUser') currentUser!: User;

  @Prop() cards!: SimplyCard[];

  searchValue = '';
  changeDialog = false;
  selectedCards: SimplyCard[] = [];
  selectAll = false;

  statusLabel = {
    [Status.Saved]: 'saved as draft',
    [Status.Submitted]: 'submitted',
  }

  created() {
    this.getTalentCards();
  }

  get filteredcards() {
    this.selectedCards = [];
    this.selectAll = false;
    return this.allCards.filter((card) => {
      return _.includes(card.userEmail.toLowerCase(), this.searchValue.toLowerCase()) ||
        _.includes(card.userSurname.toLowerCase(), this.searchValue.toLowerCase()) ||
        _.includes(card.userName.toLowerCase(), this.searchValue.toLowerCase())
    });
  }

  isDraft(status: Status) {
    return status === Status.Saved;
  }

  isSubmitted(status: Status) {
    return status === Status.Submitted;
  }

  searchChange(newValue: string) {
    this.searchValue = newValue;
  }

  openChangeDialog() {
    this.changeDialog = true;
  }

  select() {
    this.selectedCards =  this.selectAll ? _.cloneDeep(this.filteredcards) : [];
  }

  updateCheckall() {
    this.selectAll = (this.filteredcards.length === this.selectedCards.length);
  }

  closeDialog() {
    this.changeDialog = false;
    this.selectedCards = [];
    this.selectAll = false;
  }
}

