
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import Cards from '@/views/talentApp/homePage/Cards.vue';
import Projects from '@/views/talentApp/homePage/Projects.vue';
import CreateTalentCardDialog from '@/views/talentApp/CreateTalentCardDialog.vue';
import CreateProjectDialog from '@/views/talentApp/CreateProjectDialog.vue';
import TextField from '@/components/TextField.vue';
import { HomePageFilterType } from '@/interfaces/enums';
import { SimplyCard } from '@/interfaces/card';
import { Project } from '@/interfaces/projects';
import Banner from '@/components/Banner.vue';
import SelectField from '@/components/SelectField.vue';


const talentCardsModule = namespace('talentCards');
const projectsModule = namespace('projects');
const authModule = namespace('auth');


@Component(
  {
    components: {
      Cards,
      TextField,
      CreateTalentCardDialog,
      CreateProjectDialog,
      Banner,
      Projects,
      SelectField,
    }
  }
)
export default class Home extends Vue {
  @talentCardsModule.Getter('submittedCards') submittedCards!: SimplyCard[];
  @talentCardsModule.Getter('allCards') allCards!: SimplyCard[];
  @talentCardsModule.Getter('myCards') myCards!: SimplyCard[];
  @talentCardsModule.Getter('draftCards') draftCards!: SimplyCard[];
  @authModule.Getter('isCorporate') isCorporate!: boolean;
  @talentCardsModule.Action('getTalentCards') getTalentCards: any;
  @projectsModule.Getter('allProjects') allProjects!: Project[];
  @projectsModule.Action('getProjects') getProjects: any;

  filter = HomePageFilterType.All;
  filterTypes = HomePageFilterType;
  searchValue = '';
  createTcDialog = false;
  createProjectDialog = false;
  sortByItems = [ {label:'sort by name', value:'name'}, {label: 'sort by creation date', value:'creationDate'}];
  sortBy = 'name';

  created() {
    if(!this.isCorporate) {
      this.filter = HomePageFilterType.All;
    } else {
      this.filter = HomePageFilterType.My;
    }
    this.getTalentCards();
    this.getProjects();
  }

  get filteredcards() {
    let cards = [];
    switch (this.filter) {
      case 'all':
        cards = this.allCards;
        break;
      case 'my':
        cards = this.myCards;
        break;
      case 'notSubmitted':
        cards = this.draftCards;
        break;
      case 'submitted':
        cards = this.submittedCards;
        break;
      default:
        cards = this.allCards;
        break;
    }
    return cards.filter((card) => {
          return _.includes(card.name.toLowerCase(), this.searchValue.toLowerCase()) ||
            _.includes(card.surname.toLowerCase(), this.searchValue.toLowerCase())
        });
  }

  searchChange(newValue: string) {
    this.searchValue = newValue;
  }

  openCreateTcDialog() {
    this.createTcDialog = true;
  }

  openCreateProjectDialog() {
    this.createProjectDialog = true;
  }
}
