
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import Banner from '@/components/Banner.vue';
import Users from '@/views/admin/Users.vue';
import { SimplyUser } from '@/interfaces/user';
import TextField from '@/components/TextField.vue';
import CreateUserDialog from '@/views/admin/CreateUserDialog.vue';

const usersModule = namespace('users');

@Component({
  components: {
    Banner,
    Users,
    TextField,
    CreateUserDialog,
  },
})
export default class Admin extends Vue {
  @usersModule.Getter('allUsers') allUsers!: SimplyUser[];
  @usersModule.Action('getUsers') getUsers: any;

  searchValue = '';
  createUserDialog = false;

  created() {
    this.getUsers();
  }

  get filteredUsers(): SimplyUser[] {
    return this.allUsers.filter((user) => {
      if(user.name !== '' && user.name !== null && user.surname !== '' && user.surname !== null) {
        return _.includes(user.name.toLowerCase(), this.searchValue.toLowerCase()) ||
          _.includes(user.surname.toLowerCase(), this.searchValue.toLowerCase());
      } else {
        return _.includes(user.email.toLowerCase(), this.searchValue.toLowerCase());
      }
    });
  }

  searchChange(newValue: string) {
    this.searchValue = newValue;
  }

  openCreateUserDialog() {
    this.createUserDialog = true;
  }
}
