import api from '@/api';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import { ConfigState } from '@/store/config/state';

type ConfigContext = ActionContext<ConfigState, State>;

const actions = {
  async actionEnsureApplicationConfig(context: ConfigContext) {
    try {
      const data = await api.getConfiguration();
      if (data) {
        context.commit('setApplicationConfig', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root:true});
    }
  },
};

export default actions;
