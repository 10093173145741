
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SuccessNotification } from '@/interfaces/enums';

const mainModule = namespace('main');

@Component(
  {
    components: {}
  }
)
export default class SuccessAlert extends Vue {
  @mainModule.Getter('successNotification') notification!: SuccessNotification | null;
  @mainModule.Action('removeSuccessNotification') removeSuccessNotification: any;


  get notificationMessage() {
    const labels = {
      [SuccessNotification.TalentCardSaved] : 'The talent card has been successfully saved.',
      [SuccessNotification.TalentCardSubmitted] : 'The talent card has been successfully submitted.',
      [SuccessNotification.TalentCardsSaved] : 'The owner of the talent cards has been successfully updated.',
      [SuccessNotification.AnalysisReportUploaded] : 'The report has been successfully uploaded.',
      [SuccessNotification.DownloadData] : 'The report has been successfully downloaded.',
    };
    return this.notification ?  labels[this.notification] : '';
  }

  get hasNotification() {
    return this.notification !== null;
  }
}
