import { ActionContext } from 'vuex';
import * as _ from 'lodash';
import { State } from '../state';
import { TalentCardsState } from './state';
import api from '@/api';
import { Card, SimplyCard } from '@/interfaces/card';
import { Status, SuccessNotification } from '@/interfaces/enums';
import { computeModeSetting } from 'vee-validate/dist/types/components/common';
import { ExportConfig } from '@/interfaces/export';

type TalentCardsContext = ActionContext<TalentCardsState, State>;

const actions = {
  async getTalentCards(context: TalentCardsContext) {
    try {
      const data = await api.getTalentCards();
      if (data) {
        context.commit('setTalentCards', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async exportOneTalentCard(context: TalentCardsContext, card: SimplyCard ) {
    try {
      const filename = `${card.name} ${card.surname} talent card`;
      const data = await api.exportTalentCard(card.id, filename);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async exportTalentCardsInPowerpoint(context: TalentCardsContext, options: ExportConfig) {
    try {
      context.commit('setExportTcStatus', true);
      const data = await api.exportTalentCardsInPowerpoint(options.filters);
      context.dispatch('checkExportPowerpointFileAvailability', {id: data.filename, filename: options.filename});
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async checkExportPowerpointFileAvailability(context: TalentCardsContext, config: {id: string, filename: string}) {
    try {
      const data = await api.checkExportPowerpointFileAvailability(config.id);
      if(data.detail === 'Pending export still in progress'){
        setTimeout(()=> {
          context.dispatch('checkExportPowerpointFileAvailability', config);
        }, 2000);
      } else {
        context.dispatch('getExportPowerpointFile', config);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async getExportPowerpointFile(context: TalentCardsContext, config: {id: string, filename: string}) {
    try {
      await api.exportPowerpointFile(config.id, config.filename);
      context.commit('setExportTcStatus', false);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async exportTalentCardsInExcel(context: TalentCardsContext, options: ExportConfig) {
    try {
      context.commit('setExportTcStatus', true);
      const data = await api.exportTalentCardsInExcel(options.filters);
      context.dispatch('checkExportExcelFileAvailability', {id: data.filename, filename: options.filename});
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async checkExportExcelFileAvailability(context: TalentCardsContext, config: {id: string, filename: string}) {
    try {
      const data = await api.checkExportExcelFileAvailability(config.id);
      if(data.detail === 'Pending export still in progress'){
        setTimeout(()=> {
          context.dispatch('checkExportExcelFileAvailability', config);
        }, 2000);
      } else {
        context.dispatch('getExportExcelFile', config);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async getExportExcelFile(context: TalentCardsContext, config: {id: string, filename: string}) {
    try {
      await api.exportExcelFile(config.id, config.filename);
      context.commit('setExportTcStatus', false);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  setNewTalentCard(context: TalentCardsContext) {
    context.commit(
      'setNewTalentCard',
      {
      numberOfJobHistory: context.rootGetters['config/numberOfJobHistory']
      }
    );
  },
  async getTalentCard(context: TalentCardsContext, id: string) {
    try {
      const data = await api.getTalentCard(id);
      if (data) {
        context.commit('setTalentCard', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async updateTalentCard(context: TalentCardsContext, card: Card) {
    try {
      const data = await api.updateTalentCard(card);
      if (data) {
        if (card.status === Status.Submitted) {
          context.dispatch('main/setSuccessNotification', SuccessNotification.TalentCardSubmitted, {root: true});
        }
      }
      return true;
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
      return false;
    }
  },
  async updateTalentCards(context: TalentCardsContext, cards: SimplyCard[]) {
    try {
      const data = await api.updateTalentCards(cards);
      if (data) {
        context.dispatch('main/setSuccessNotification', SuccessNotification.TalentCardsSaved, {root: true});
        context.dispatch('getTalentCards');
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async createTalentCard(context: TalentCardsContext, card: Card) {
    try {
      const data = await api.createTalentCard(card);
      if (data) {
        context.commit('addNewTalentCardToList', {
          name: card.name,
          surname: card.surname,
          status: card.status,
          id: card.id,
          businessTitle: card.businessTitle,
          creationDate: card.creationDate,
          userEmail: card.userEmail,
        });
        return data;
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  cleanTalentCard(context: TalentCardsContext) {
    context.commit('setTalentCard', null);
  },
  async getManagers(context: TalentCardsContext) {
    try {
      const data = await api.getManagers();
      if (data) {
        context.commit('setManagers', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  removeNotification(context: TalentCardsContext) {
    context.commit('removeNotification');
  },
  async uploadPicture(context: TalentCardsContext, fileInfo: { id: string; file: any; }) {
    try {
      const data = await api.uploadPhoto(fileInfo.id, fileInfo.file);
      if (data) {
        context.commit('setTalentCardPicture', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async removePicture(context: TalentCardsContext, id: string) {
    try {
      const data = await api.deletePhoto(id);
      if (data) {
        context.commit('removeTalentCardPicture');
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async updatePicture(context: TalentCardsContext, fileInfo: { id: string; file: any; }) {
    try {
      await api.deletePhoto(fileInfo.id);
      context.dispatch('uploadPicture', fileInfo);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async removeTalentCard(context: TalentCardsContext, card: SimplyCard) {
    if (card.id) {
      try {
        await api.deleteTalentCard(card.id);
        context.commit('deleteTalentCard', card.id);
      } catch (error) {
        await context.dispatch('main/setNewError', error, {root: true});
      }
    }
  },
};

export default actions;
