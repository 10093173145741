import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { State } from './state';
import talentCardsModule from './talentCards';
import authModule from './auth';
import mainModule from './main';
import configModule from './config';
import usersModule from './users';
import projectsModule from './projects';
import analysisModule from './analysis';
import nudgeModule from './nudge';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  state: {},
  modules: {
    talentCards: talentCardsModule,
    auth: authModule,
    main: mainModule,
    config: configModule,
    users: usersModule,
    projects: projectsModule,
    analysis: analysisModule,
    nudge: nudgeModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
