
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import { Project } from '@/interfaces/projects';
import { HomePageFilterType, ExportFormat } from '@/interfaces/enums';
import { SimplyCard } from '@/interfaces/card';
import Cards from '@/views/talentApp/homePage/Cards.vue';
import CreateProjectDialog from '@/views/talentApp/CreateProjectDialog.vue';
import CreateAnalysisDialog from '@/views/analysis/CreateAnalysisDialog.vue';

const talentCardsModule = namespace('talentCards');
const projectsModule = namespace('projects');
const authModule = namespace('auth');

@Component(
  {
    components: {
      Cards,
      CreateProjectDialog,
      CreateAnalysisDialog,
    }
  }
)
export default class Projects extends Vue {
  @Prop() projects!: Project[];
  @Prop() sortBy!: string;

  @talentCardsModule.Getter('allCards') allCards!: SimplyCard[];
  @talentCardsModule.Action('exportTalentCardsInPowerpoint') exportTalentCardsInPowerpoint: any;
  @talentCardsModule.Action('exportTalentCardsInExcel') exportTalentCardsInExcel: any;
  @projectsModule.Action('removeProject') removeProject : any;
  @projectsModule.Action('updateProject') updateProject: any;
  @projectsModule.Getter('editedProject') editedProject: any;
  @projectsModule.Action('getProject') getProject : any;
  @authModule.Getter('isAdmin') isAdmin!: boolean;

  filterTypes = HomePageFilterType;
  deleteConfirmation = false;
  projectToDelete: Project | null = null;
  createProjectDialog = false;
  projectToExport: Project | null = null;
  exportProjectConfirmation = false;
  formatToExport: ExportFormat | null = null;
  createAnalysisDialog = false;
  projectMailto: Project | null = null;

  getTalentsCards(idList: string[]): SimplyCard[] {
    return this.allCards.filter((talent) => {
      return _.includes(idList, talent.id);
    });
  }


  async editProject(project: Project) {
    await this.getProject(project.id);
    if (this.editedProject) {
      this.createProjectDialog = true;
    }
  }

  deleteProject() {
    if(this.projectToDelete) {
      this.removeProject(this.projectToDelete.id);
    }
    this.closeConfirmationModal();
  }

  openConfirmationModal(project: Project) {
    this.deleteConfirmation = true;
    this.projectToDelete = project;
  }

  closeConfirmationModal() {
    this.deleteConfirmation = false;
    this.projectToDelete = null;

  }

  exportProject() {
    if(this.formatToExport === ExportFormat.Powerpoint) {
      this.exportTalentCardsInPowerpoint(
        {filename: 'all talent cards of ' + this.projectToExport!.name, filters:{project_id: this.projectToExport!.id }},
      );
    } else {
      this.exportTalentCardsInExcel(
        {filename: 'all talent cards of ' + this.projectToExport!.name, filters:{project_id: this.projectToExport!.id }},
      );
    }
    this.closeExportProjectConfirmation();
  }

  openExportProjectConfirmation(project: Project, format: ExportFormat) {
    this.projectToExport= project;
    this.exportProjectConfirmation = true;
    this.formatToExport = format;
  }

  closeExportProjectConfirmation() {
    this.projectToExport = null;
    this.exportProjectConfirmation = false;
    this.formatToExport = null;
  }

  openCreateAnalysesDialog(project: Project) {
    this.createAnalysisDialog = true;
    this.projectMailto = project
  }

  getProjectMailto() {
    if (this.projectMailto) {
      return {id: this.projectMailto.id, name: this.projectMailto.name}
    }
  }

  get sortedProjects() {
    if(this.sortBy === 'creationDate') {
      return _.sortBy( this.projects, this.sortBy);
    }
    return _.sortBy( this.projects, [(p) => (p.name.toLowerCase())]);
  }

  get exportPowerpoint(): ExportFormat{
    return ExportFormat.Powerpoint;
  }

  get exportExcel(): ExportFormat{
    return ExportFormat.Excel;
  }
}
