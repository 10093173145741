
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Analysis } from '@/interfaces/analysis';
import * as _ from 'lodash';
import { AnalysisStatus } from '@/interfaces/enums';
import Title2 from '@/components/Title2.vue';
import UploadExcelReportDialog from './UploadExcelReportDialog.vue';
import UploadPdfReportDialog from './UploadPdfReportDialog.vue';

const authModule = namespace('auth');
const analysisModule = namespace('analysis');

@Component({
  components: {
    Title2,
    UploadExcelReportDialog,
    UploadPdfReportDialog,
  }
})
export default class AnalysisPage extends Vue {

  @authModule.Getter('isAdmin') isAdmin!: boolean;
  @authModule.Getter('isAnalyst') isAnalyst!: boolean;
  @analysisModule.Action('getAnalysis') getAnalysis: any;
  @analysisModule.Getter('currentAnalysis') currentAnalysis!: Analysis;
  @analysisModule.Action('downloadData') downloadData: any;
  @analysisModule.Action('downloadReportsExcel') downloadReportsExcel: any;
  @analysisModule.Action('downloadReportsPDF') downloadReportsPDF: any;
  
  tab = 1;

  tableHeadersAdmin = [
    { text: 'Talent Name', value: 'talentName', sortable: true },
    { text: 'Talent Surname', value: 'talentSurname', sortable: true },
    { text: 'Owner Email', value: 'ownerEmail', sortable: true },
    { text: 'Details', value: 'results', sortable: true },
  ];

  tableHeadersAnalyst = [
    { text: 'Talent id', value: 'talentId', sortable: true },
    { text: 'Details', value: 'results', sortable: true },
  ];

  statusLabel = {
    [AnalysisStatus.Done] : 'Done',
    [AnalysisStatus.Requested] : 'Requested',
    [AnalysisStatus.Doing] : 'In Progress',
  }

  statusIcon = {
    [AnalysisStatus.Done] : 'mdi-check-circle-outline',
    [AnalysisStatus.Requested] :  'mdi-progress-clock',
    [AnalysisStatus.Doing] : 'mdi-folder-search-outline',
  }

  created() {
    if(this.$route.params.id) {
      this.getAnalysis(this.$route.params.id);
    }
  }

  get isDone(): boolean {
   return this.currentAnalysis.status === AnalysisStatus.Done;
  }

  get isRequested(): boolean {
    return this.currentAnalysis.status === AnalysisStatus.Requested;
  }

  downloadDataButton(id: string, projectId: string, projectName: string) {
     this.downloadData({filename: `all talent cards of ${projectName}`,
                        filters:{project_id: projectId },
                        id: id});
  }

  downloadReportsButtonExcel(id: string, analysisName: string) {
     this.downloadReportsExcel({id: id,
                                filename: `report_${analysisName}`});
  }

  downloadReportsButtonPDF(id: string, analysisName: string) {
     this.downloadReportsPDF({id: id,
                              filename: `report_${analysisName}`});
  }

}
