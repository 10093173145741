
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';

@Component
export default class TextareaField extends Vue {
  @Prop() title!: string;
  @Prop() explanation?: string;
  @Prop() maxCharacters!: number;
  @Prop() maxLines!: number;
  @Prop() text!: string;
  @Prop() required!: boolean;
  @Prop() rules!: void[];

  showMaxCharacters = true;

  toggleMaxCharacters(fieldNoValid: boolean) {
    this.showMaxCharacters = !fieldNoValid;
  }

  isEmpty() {
    return this.text === '';
  }
}
