import { AuthState } from './state';
import { UserRole } from '@/interfaces/enums';
import { User } from '@/interfaces/user';

const getters = {
  authToken(state: AuthState): string {
    return state.token;
  },

  isLoggedIn(state: AuthState): boolean| null {
    return state.isLoggedIn;
  },

  isCorporate(state: AuthState): boolean {
    return state.userProfile !== null && state.userProfile.role === UserRole.Corporate;
  },

  isAdmin(state: AuthState): boolean {
    return state.userProfile !== null && state.userProfile.role === UserRole.Admin;
  },

  isAnalyst(state: AuthState): boolean {
    return state.userProfile !== null && state.userProfile.role === UserRole.Analyst;
  },

  isManager(state: AuthState): boolean {
    return state.userProfile !== null && state.userProfile.role === UserRole.Manager;
  },

  currentUser(state: AuthState) : User | null {
    return state.userProfile;
  },
};

export default getters;
