
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Title1 from '@/components/Title1.vue';
import SelectField from '@/components/SelectField.vue';
import Explanation from '@/components/Explanation.vue';
import NudgeBox from '@/components/NudgeBox.vue';
import { Openess } from '@/interfaces/enums';
import { InternationalMobility } from '@/interfaces/card';

const nudgeModule = namespace('nudge');

@Component({
  components: {
  Title1,
  SelectField,
  Explanation,
  NudgeBox,
  },
  })
export default class Mobility extends Vue {
  @Prop() mobility!: InternationalMobility;
  @nudgeModule.Getter('nudge_box4') nudge_box4_vue!: string;

  relocation = [
    {
      label: 'Anytime',
      value: Openess.Anytime,
    },
     {
      label: 'In the future',
      value: Openess.InTheFuture,
    },
     {
      label: 'Depends on role',
      value: Openess.DependsRole,
    },
     {
      label: 'Depends on location',
      value: Openess.DependsLocation,
    },
     {
      label: 'Not open',
      value: Openess.NotOpen,
    },
  ];
}
