import { Module } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { UsersState } from './state';
import { State } from '../state';

const defaultState: UsersState = {
  users: [],
  editedUser: null,
};

const usersModule: Module<UsersState, State> = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default usersModule;
