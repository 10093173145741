import { ProjectsState } from './state';
import { Project } from '@/interfaces/projects';


const getters = {

  allProjects(state: ProjectsState): Project[] {
    return state.projects;
  },

  editedProject(state: ProjectsState): Project | null {
    return state.editedProject;
  },

};

export default getters;
