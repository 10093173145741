
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Analysis, UploadAnalysis } from '@/interfaces/analysis';

const analysisModule = namespace('analysis');

@Component(
  {
    components: {}
  }
)
export default class UploadPdfReportDialog extends Vue {
  @Prop() analysis!: Analysis;

  @analysisModule.Action('importAnalysis') importAnalysis!: (uploadInfo: UploadAnalysis) => Promise<void>;

  pdfFile: File | null = null;
  openDialogPDF = false;
  
  importPDF(id : string): void {
    if (this.pdfFile) {
      this.importAnalysis({file:this.pdfFile, id: id, format: 'pdf'});
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.pdfFile = null;
    this.openDialogPDF = false;
  }
}
