import { UsersState } from './state';
import { SimplyUser, User } from '@/interfaces/user';


const getters = {
  allUsers(state: UsersState): SimplyUser[] {
    return state.users;
  },

  editedUser(state: UsersState): User | null {
    return state.editedUser;
  },
}

export default getters;
