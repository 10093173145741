import axios from 'axios';
import { apiUrl } from '@/env';
import store from '@/store';
import router from '@/router';

axios.defaults.baseURL = `${apiUrl}/api/v1`;
axios.defaults.headers.Authorization = `Bearer ${store.getters['auth/authToken']}`;
axios.defaults.headers.Pragma = 'no-cache';
// Add a request interceptor
axios.interceptors.request.use(function (config) {
  if (localStorage.getItem('token') !== null) {
    config.headers!.Authorization = `Bearer ${store.getters['auth/authToken']}`;
  }
  return config
}, function(error) {
  return Promise.reject(error);
});
// Add a response interceptor
axios.interceptors.response.use( (response) => {
  // Return a successful response back to the calling service
  return response;
}, (err) => {
  const error = new Error(err.response?.data.detail);
  // Return any error which is not due to authentication back to the calling service
  if (err.response?.status === 401) {
    return new Promise((resolve, reject) => {
      store.dispatch('auth/actionLogOut');
      reject(error);
    });
  } else if (err.response?.status === 403) {
    return new Promise((resolve, reject) => {
      router.push('/403');
      reject(error);
    });
  }

  return Promise.reject(error);
});
