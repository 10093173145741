
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TextField extends Vue {
  @Prop() label?: string;
  @Prop() text?: string;
  @Prop() maxlength?: string;
  @Prop() minlength?: string;
  @Prop() required!: boolean;
  @Prop() rules!: void[];
  @Prop() disabled?: boolean;
  @Prop() placeholder?: string;

  get isEmpty() {
    return this.text === '';
  }
}
