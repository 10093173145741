
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SimplyUser, User } from '@/interfaces/user';
import { UserRole } from '@/interfaces/enums';
import { namespace } from 'vuex-class';

const usersModule = namespace('users');
const authModule = namespace('auth');

@Component
export default class Users extends Vue {
  @Prop() users!: SimplyUser[];

  @authModule.Getter('currentUser') currentUser! : User;
  @usersModule.Action('removeUser') removeUser : any;
  @usersModule.Action('getUser') getUser : any;
  @usersModule.Getter('editedUser') editedUser : any;

  roleLabel = {
    [UserRole.Admin] : 'administrator',
    [UserRole.Corporate] : 'user',
    [UserRole.Manager] : 'superuser',
    [UserRole.Analyst] : 'analyst',
  }

  deleteConfirmation = false;
  userToDelete: SimplyUser | null = null; 

  closeConfirmationModal() {
    this.deleteConfirmation = false;
    this.userToDelete = null;
  }

  async editUser(user: SimplyUser){
    await this.getUser(user.id);
    if (this.editedUser) {
      this.$emit("openCreateUserDialog");
    }
  }

  deleteUser() {
    if(this.userToDelete) {
      this.removeUser(this.userToDelete.id);
    }
    this.closeConfirmationModal();
  }

  openConfirmationModal(user: SimplyUser) {
    this.deleteConfirmation = true;
    this.userToDelete = user;
  }
}
