import { Module } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { AnalysisState } from './state';
import { State } from '../state';

const defaultState: AnalysisState = {
  analyses: [],
  analysis: null,
  downloadDataAnalysisInProgress: false,
  currentAnalysis: null,
};

const analysisModule: Module<AnalysisState, State> = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default analysisModule;
