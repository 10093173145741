
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';
import { namespace } from 'vuex-class';
import Title2 from '@/components/Title2.vue';
import SelectField from '@/components/SelectField.vue';
import { Card } from '@/interfaces/card';
import { LabelledValue } from '@/interfaces/utils';
import { SimplyUser, User } from '@/interfaces/user';
import ButtonGroupField from '@/components/ButtonGroupField.vue';
import { Accessibility } from '@/interfaces/enums';

const authModule = namespace('auth');
const usersModule = namespace('users');

@Component({
  components: {
    Title2,
    SelectField,
    ButtonGroupField,
  },
})
export default class AboutCard extends Vue {
  @authModule.Getter('isAdmin') isAdmin!: boolean;
  @authModule.Getter('isManager') isManager!: boolean;
  @authModule.Getter('currentUser') currentUser!: User;
  @usersModule.Getter('allUsers') allUsersList!: SimplyUser[];
  @usersModule.Action('getUsers') getUsers: any;

  @Prop() card!: Card;

  created() {
    if (this.isAdmin || this.isManager) {
      this.getUsers();
    }
  }

  get userLabels(): LabelledValue[] {
    return this.allUsersList.map((user) => {
      return {
        label: (user.name !== '' && user.name !== null &&
          user.surname !== '' && user.surname !== null
        ) ? `${user.name} ${user.surname}` : user.email,
        value: user.id
      } as LabelledValue;
    })
  }

  get isAccessibilityFieldDisabled(): boolean {
    return !this.isAdmin && this.card.userId !== this.currentUser.id;
  }

  privateItems = [
    {
      label: 'Public',
      value: Accessibility.Public
    },
    {
      label: 'Private',
      value: Accessibility.Private
    }
  ];

}
