import { MainState } from './state';
import { LabelledValue } from '@/interfaces/utils';
import Vue from 'vue';
import { SuccessNotification } from '@/interfaces/enums';

const mutations = {
  setNationalities(state: MainState, payload: LabelledValue[]) {
    Vue.set(state, 'nationalities', payload);
  },
  setLanguages(state: MainState, payload: LabelledValue[]) {;
    Vue.set(state, 'languages', payload);
  },
  setCountries(state: MainState, payload: LabelledValue[]) {
    Vue.set(state, 'countries', payload);
  },
  setError(state: MainState, error: Error | null) {
    Vue.set(state, 'error', error);
    setTimeout(() => {
      Vue.set(state, 'error', null);
    }, 8000);
  },
  removeError(state: MainState) {
    Vue.set(state, 'error', null);
  },
  removeSuccessNotification(state: MainState) {
    Vue.set(state, 'successNotification', null);
  },
  setSuccessNotification(state: MainState, notification: SuccessNotification) {
    Vue.set(state, 'successNotification', notification);
    setTimeout(() => {
      Vue.set(state, 'successNotification', null);
    }, 8000);
  },
};

export default mutations;
