import { AnalysisState } from './state';
import { Analysis } from '@/interfaces/analysis';
import Vue from 'vue';
import * as _ from 'lodash';

const mutations = {
  setAnalyses(state: AnalysisState, payload: Analysis[]) {
    Vue.set(state, 'analyses', payload);
  },

  setAnalysis(state: AnalysisState, payload: Analysis) {
    Vue.set(state, 'currentAnalysis', payload);
  },

  addNewAnalysis(state: AnalysisState, payload: Analysis) {
    state.analyses.push(payload);
  },

  // updateAnalyse(state: AnalysisState, payload: Analysis) {
  //   const index = _.findIndex(state.analysis, { id: payload.id });
  //   state.analysis.splice(index, 1, payload);
  // },

  setDownloadDataAnalysisStatus(state: AnalysisState, isInProgress: boolean) {
    Vue.set(state, "downloadDataAnalysisInProgress", isInProgress);
  },
};

export default mutations;
