
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import TextField from '@/components/TextField.vue';
import Title2 from '@/components/Title2.vue';
import { Project } from '@/interfaces/projects';
import { SimplyCard  } from '@/interfaces/card';
import { LabelledValue } from '@/interfaces/utils';
import SelectField from '@/components/SelectField.vue';
import { fieldRules } from '@/utils';

const projectModule = namespace('projects');
const talentCardsModule = namespace('talentCards');

@Component(
  {
    components: {
      TextField,
      Title2,
      SelectField,
    }
  }
)
export default class CreateProjectDialog extends Vue {
  @Prop() createProjectDialog!: boolean;

  @projectModule.Action('createProject') createProject: any;
  @projectModule.Action('removeProjectEdition') removeProjectEdition: any;
  @projectModule.Action('updateProject') updateProject: any;
  @projectModule.Getter('editedProject') editedProject: any;
  @talentCardsModule.Getter('allCards') allCards!: SimplyCard[];

  project: Project = { name: '', talentsIds: [] };
  mode: 'creation' | 'edition' | null = null;
  
  get talentsList(): LabelledValue[] {
    return _.sortBy(this.allCards
      .map((talent) => {
        return {label: `${talent.name} ${talent.surname}`, value: talent.id}  as LabelledValue;
      }),
      'label');
  }

  async createNewProject() {
    await this.createProject(this.project);
    this.closeDialog();
  }

  async editProject() {
    const editedProject = {
      id: this.project.id,
      name: this.project.name,
      talentsAdded: _.difference(this.project.talentsIds, this.editedProject.talentsIds),
      talentsDeleted: _.difference(this.editedProject.talentsIds, this.project.talentsIds),
    };
    await this.updateProject(editedProject);
    this.closeDialog();
  }

  closeDialog() {
    this.removeProjectEdition();
    this.$emit('closeDialog');
  }

  nameValidatorRules = [fieldRules.minLength(1)];
  
  created() {
    this.initProject();
  }

  @Watch('createProjectDialog')
  initProject() {
    if (this.createProjectDialog) {
      if (this.editedProject === null){
        this.mode = 'creation';
        this.project = {
          name: '',
          talentsIds: [],
        };
      }
      else {
        this.mode = 'edition';
        this.project = _.cloneDeep(this.editedProject);
      }
    }
  }

  get isValidForCreation() {
    return this.project ? !_.isEmpty(this.project.name): false;
  }

  get isEditionMode(): boolean {
    return this.mode === 'edition';
  }
}
