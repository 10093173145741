
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import AboutTalent from '@/views/talentApp/talentForm/AboutTalent.vue';
import AboutManager from '@/views/talentApp/talentForm/AboutManager.vue';
import AboutCard from '@/views/talentApp/talentForm/AboutCard.vue';
import TrajectorySection from '@/views/talentApp/talentForm/Trajectory.vue';
import PerformanceSection from '@/views/talentApp/talentForm/Performance.vue';
import WhoIsFillingForm from '@/views/talentApp/talentForm/WhoIsFilling.vue';
import FuturePerformanceSection from '@/views/talentApp/talentForm/FuturePerformance.vue';
import Mobility from '@/views/talentApp/talentForm/Mobility.vue';
import CapacityToGrowSection from '@/views/talentApp/talentForm/CapacityToGrow.vue';
import TalentGroupForm from '@/views/talentApp/talentForm/TalentGroup.vue';
import { Card } from '@/interfaces/card';
import { Status } from '@/interfaces/enums';
import { Manager } from '@/interfaces/card';
import { scrollToElt } from '@/utils';

const mainModule = namespace('main');
const talentCardsModule = namespace('talentCards');
const configModule = namespace('config');
const nudgeModule = namespace('nudge');



@Component(
  {
    components: {
      AboutTalent,
      TrajectorySection,
      PerformanceSection,
      AboutManager,
      WhoIsFillingForm,
      FuturePerformanceSection,
      Mobility,
      CapacityToGrowSection,
      TalentGroupForm,
      AboutCard,
    }
  }
)
export default class TalentForm extends Vue {
  @mainModule.Action('getCountries') getCountries: any;
  @mainModule.Action('getLanguages') getLanguages: any;
  @mainModule.Action('getNationalities') getNationalities: any;
  @nudgeModule.Action('getNudges') getNudges: any;
  @mainModule.Action('setNewError') setNewError: any;
  @talentCardsModule.Action('getTalentCard') getTalentCard: any;
  @talentCardsModule.Action('updateTalentCard') updateTalentCard: any;
  @talentCardsModule.Action('cleanTalentCard') cleanTalentCard: any;
  @talentCardsModule.Action('getManagers') getManagers: any;
  @talentCardsModule.Getter('currentCard') card!: Card;
  @configModule.Getter('mandatoryFields') mandatoryFields!: any[];

  submitBtn = true;
  menuBtn = true;
  mini = true;
  menuMini = true;
  validForm = true;

  sections = [
    {link: 'about-talent', label: 'About the talent'},
    {link: 'about-line-manager', label: 'About the line manager'},
    {link: 'about-hr-manager', label: 'About the hr partner'},
    {link: 'who-is-filling', label: 'Who is the primary writer?'},
    {link: 'trajectory', label: 'Trajectory'},
    {link: 'performance', label: 'Performance'},
    {link: 'future-performance', label: 'Future development'},
    {link: 'mobility', label: 'Interest in international mobility'},
    {link: 'capacity-to-grow', label: 'Capacity to grow'},
    {link: 'talent-group', label: 'Talent group'},
  ];

  created() {
    this.getCountries();
    this.getLanguages();
    this.getNationalities();
    this.getManagers();
    this.getNudges();

    if(this.$route.params.id) {
      this.getTalentCard(this.$route.params.id);
    }
  }

  beforeDestroy() {
   this.cleanTalentCard();
  }

  toggleSubmitBtn() {
    this.mini = ! this.mini;
  }

  toggleMenuBtn() {
    this.menuMini = ! this.menuMini;
  }

  saveTC() {
    if (this.card.name !== '' && this.card.surname !== ''){
      this.card.status = Status.Saved;
      this.updateTalentCard(this.card);
    } else {
      this.setNewError(new Error('Name and surname can\'t be empty'))
    }

  }

  async submitTC() {
    if(this.isValidForSubmission()){
      this.card.status = Status.Submitted;
      const success = await this.updateTalentCard(this.card);
      if (success) {
        this.$router.push('talent/cards');
      } else {
        this.toggleSubmitBtn();
      }
    } else  {
      this.saveTC();
      this.toggleSubmitBtn();
    }

  }

  get notValidFields() {
    return this.mandatoryFields.filter((field) => {
      return _.isEmpty(_.get(this.card, field.key));
    });
  }

  isValidForSubmission() {
    return this.notValidFields.length === 0;
  }

  scrollTo(eltId: string) {
    scrollToElt(eltId);
    this.menuMini = true;
  }
}
