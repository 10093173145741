import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#111',
        accent: '#5D569D',
        secondary: '#8C8C8C',
        bgGray: '#E8E8E8',
        error: '#ED5351',
        success: '#4CAF50',
      },
    },
  },
});
