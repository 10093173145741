import { AnalysisState } from './state';
import { Analysis } from '@/interfaces/analysis';


const getters = {
  allAnalyses(state: AnalysisState): Analysis[] {
    return state.analyses;
  },

  currentAnalysis(state: AnalysisState): Analysis | null {
    return state.currentAnalysis;
  },

}

export default getters;
