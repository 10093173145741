
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Title1 from '@/components/Title1.vue';
import TextareaField from '@/components/TextareaField.vue';
import NudgeBox from '@/components/NudgeBox.vue';
import { FutureDevelopment } from '@/interfaces/card';
import { fieldRules } from '@/utils';

const nudgeModule = namespace('nudge');

@Component({
  components: {
  Title1,
  TextareaField,
  NudgeBox
  },
  })
export default class FuturePerformanceSection extends Vue {
  @Prop() future!: FutureDevelopment;
  @Prop() name!: string;
  @Prop() surname!: string;

  validatorRules = [ fieldRules.containsName(this.name), fieldRules.containsSurname(this.surname) ];

  fields = [
    {
      title: 'FUTURE ROLES ASPIRATIONS',
      explanation: 'State one or two possible long term career aspirations (beyond the next role) for this talent.',
      rules: [...this.validatorRules, fieldRules.isValidForExport(70, 2, 60)],
      maxLines: 2,
      maxCharacters: 70,
      key: 'futureRoles',
      required: false,
      id: 'future-roles',
    },
    {
      title: 'OPTIONS FOR NEXT ROLES',
      explanation: 'What are the best next role(s) to develop this individual and prepare them for future aspirations?',
      rules: [...this.validatorRules, fieldRules.isValidForExport(130, 3, 60)],
      maxLines: 3,
      maxCharacters: 130,
      key: 'nextRole',
      required: true,
      id: 'next-role',
    }
  ]
  fields_secondpart = [
    {
      title: 'EXPERIENCE/EXPOSURE TO BE GAINED IN THE NEXT 2-5 YEARS',
      explanation: 'In order to be seen as an ideal candidate for future roles, what does this person need to gain in terms' +
        ' of essential experience (functional, business, other …) or essential exposure to stakeholders?',
      rules: [...this.validatorRules, fieldRules.isValidForExport(200, 4, 60)],
      maxLines: 4,
      maxCharacters: 200,
      key: 'experienceNeeded',
      required: true,
      id: 'experience-needed',
    },
     {
      title: 'SINGLE MOST IMPORTANT FOCUS AREA',
      explanation: 'What is the one big thing this person should focus on to get to the next level '+
      ' or increase their impact in their current role?',
      rules: [...this.validatorRules, fieldRules.isValidForExport(130, 3, 60)],
      maxLines: 3,
      maxCharacters: 130,
      key: 'nextFocus',
      required: true,
      id: 'next-focus',
    },
  ];
}
