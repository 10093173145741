import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Forbidden from '@/views/Forbidden.vue';
import TalentApp from '@/views/talentApp/TalentApp.vue';
import Home from '@/views/talentApp/homePage/Home.vue';
import TalentForm from '@/views/talentApp/talentForm/TalentForm.vue';
import Admin from '@/views/admin/Admin.vue';
import Analysis from '@/views/analysis/HomeAnalysis.vue';
import AnalysisPage from '@/views/analysis/AnalysisPage.vue';
import ChangeOwnership from '@/views/changeOwnership/ChangePage.vue'
import store from '@/store'
import { UserRole } from '@/interfaces/enums';
import { getLocalRole, getLocalToken } from '@/utils';
import _ from 'lodash';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: Login,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/403',
    component: Forbidden,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/',
    component: TalentApp,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'talent/cards',
        name: 'cardList',
        component: Home,
        meta: {
          requiresAuth: true,
          roles: [UserRole.Admin, UserRole.Corporate, UserRole.Manager],
        },
      },
      {
        path: 'talent/form/:id',
        name: 'cardForm',
        component: TalentForm,
        meta: {
          requiresAuth: true,
          roles: [UserRole.Admin, UserRole.Corporate, UserRole.Manager],
        },
      },
      {
        path: 'admin',
        name: 'admin',
        component: Admin,
        meta: {
          requiresAuth: true,
          roles: [UserRole.Admin],
        },
      },
      {
        path: 'change-ownership',
        name: 'change-ownership',
        component: ChangeOwnership,
        meta: {
          requiresAuth: true,
          roles: [UserRole.Admin, UserRole.Manager],
        },
      },
      {
        path: '/analysis',
        name: 'analysis',
        component: Analysis,
        meta: {
          requiresAuth: true,
          roles: [UserRole.Admin, UserRole.Analyst],
        }
      },
      {
        path: '/analysis/:id',
        name: 'analysisPage',
        component: AnalysisPage,
        meta: {
          requiresAuth: true,
          roles: [UserRole.Admin, UserRole.Analyst],
        }
      },
    ],
  },
  {
    path: '/*', redirect: '/talent/cards',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.config.application.use_sso && getLocalToken() === null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      });
    } else {
      const accessRoles: UserRole[] = _.last(to.matched)!.meta.roles;
      if (_.includes(accessRoles, getLocalRole())) {
        next();
      } else {
        if (getLocalRole() === UserRole.Analyst) {
          next({ path: '/analysis' });
        } else {
          next({ path: '/talent/cards' });
        }
      }
    }
  } else {
    next();
  }
});

export default router;
