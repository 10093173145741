
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FieldItem } from '@/interfaces/fields';

@Component
export default class SelectField extends Vue {
  @Prop() label?: string;
  @Prop() options!: FieldItem[];
  @Prop() multiple!: boolean;
  @Prop() max?: number;
  @Prop() rules?: string;
  @Prop() explanation?: string;
  @Prop() info?: string;
  @Prop() selection!: string | string[];
  @Prop() big!: boolean;
  @Prop() required!: boolean;
  @Prop() disabled?: boolean;
  @Prop({default: 'Select'}) placeholder?: string;

  handleChange(newValue: string[]) {
    if(this.multiple && this.max && newValue.length > this.max) {
      newValue.pop();
    }
    this.$emit('update:selection', newValue)
  }

  nbToDisplay!: number;

  created() {
   this.nbToDisplay = this.big ? 4 : 2;
  }

  get isEmpty() {
    return this.multiple ? this.selection.length === 0 : (this.selection === '' || this.selection === null);
  }

}
