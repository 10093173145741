
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Analysis, UploadAnalysis } from '@/interfaces/analysis';

const analysisModule = namespace('analysis');

@Component(
  {
    components: {}
  }
)
export default class UploadExcelReportDialog extends Vue {
  @Prop() analysis!: Analysis;

  @analysisModule.Action('importAnalysis') importAnalysis!: (uploadInfo: UploadAnalysis) => Promise<void>;

  excelFile: File | null = null;
  openDialogExcel = false;
  
  importExcel(id : string): void {
    if (this.excelFile) {
      this.importAnalysis({file:this.excelFile, id: id, format: 'excel'});
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.excelFile = null;
    this.openDialogExcel = false;
  }
}
