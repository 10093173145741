
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import TextField from '@/components/TextField.vue';
import Title2 from '@/components/Title2.vue';
import { UserRole } from '@/interfaces/enums';
import { User } from '@/interfaces/user';
import { fieldRules, isValidEmailField } from '@/utils';
import NationalityField from '@/components/NationalityField.vue';
import ButtonGroupField from '@/components/ButtonGroupField.vue';

const mainModule = namespace('main');
const userModule = namespace('users');

@Component(
  {
    components: {
      TextField,
      NationalityField,
      ButtonGroupField,
      Title2,
    }
  }
)
export default class CreateUserDialog extends Vue {
  @Prop() isOpen!: boolean;

  @mainModule.Action('getNationalities') getNationalities: any;
  @userModule.Action('createUser') createUser: any;
  @userModule.Action('removeUserEdition') removeUserEdition: any;
  @userModule.Action('updateUser') updateUser: any;
  @userModule.Getter('editedUser') editedUser: any;

  roleLabels =  [
    {
      label: 'User',
      value: UserRole.Corporate
    },
    {
      label: 'Superuser',
      value: UserRole.Manager,
    },
    {
      label: 'Analyst',
      value: UserRole.Analyst,
    },
    {
      label: 'Admin',
      value: UserRole.Admin,
    },
  ];

  user: User | null = null;
  mode: 'creation' | 'edition' | null = null;

  created() {
    this.getNationalities();
    this.initUser();
  }

  @Watch('isOpen')
  initUser() {
    if (this.isOpen) {
      if (this.editedUser === null){
        this.mode = 'creation';
        this.user = {
          name: '',
          surname: '',
          username: null,
          email: '',
          role: UserRole.Corporate,
          nationality: [],
        };
      }
      else {
        this.mode = 'edition';
        this.user = this.editedUser;
      }
    }
  }

  async createNewUser() {
    await this.createUser(this.user);
    this.closeDialog();
  }

  async editUser() {
    await this.updateUser(this.user);
    this.closeDialog();
  }

  nameValidatorRules = [fieldRules.minLength(1)];
  emailValidatorRules = [fieldRules.isValidEmail];

  closeDialog() {
    this.removeUserEdition();
    this.$emit('closeDialog');
  }

  resetUser() {
    this.user = {
      name: '',
      surname: '',
      username: null,
      email: '',
      role: UserRole.Corporate,
      nationality: [],
    };
  }

  get isValidForm() {
    return this.user ? !_.isEmpty(this.user.name) && !_.isEmpty(this.user.surname) &&
           !_.isEmpty(this.user.email) && isValidEmailField(this.user.email) &&
           !_.isEmpty(this.user.nationality) && !_.isEmpty(this.user.role)
           : false;
  }

  get isEditionMode(): boolean {
    return this.mode === 'edition';
  }

  updateField(key: string, newValue: string[]) {
    Vue.set(this.user!, key, newValue);
  }
}

