
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NudgeBox extends Vue {
  @Prop() nudgestring!: string;

  get isEmpty() {
    return this.nudgestring === '';
  }


}
