import { ActionContext } from 'vuex';
import { State } from '../state';
import { MainState } from './state';
import api from '@/api';
import { SuccessNotification } from '@/interfaces/enums';

type MainContext = ActionContext<MainState, State>;

const actions = {
  async getNationalities(context: MainContext) {
    try {
      const data = await api.getNationalities();
      if (data) {
        context.commit('setNationalities', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root:true});
    }
  },
  async getCountries(context: MainContext) {
    try {
      const data = await api.getCountries();
      if (data) {
        context.commit('setCountries', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root:true});
    }
  },
  async getLanguages(context: MainContext) {
    try {
      const data = await api.getLanguages();
      if (data) {
        context.commit('setLanguages', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root:true});
    }
  },
  setNewError(context: MainContext, error: Error) {
    context.commit('setError', error);
  },
  removeError(context: MainContext) {
    context.commit('removeError');
  },
  setSuccessNotification(context: MainContext, notification: SuccessNotification) {
    context.commit('setSuccessNotification', notification);
  },
  removeSuccessNotification(context: MainContext) {
    context.commit('removeSuccessNotification');
  },
};

export default actions;
