import * as _ from 'lodash';
import { ConfigState } from './state';
import { ElementFE } from '@/interfaces/utils';

const getters = {
  mandatoryFields(state: ConfigState): ElementFE[] {
    return state.mandatoryFields;
  },
  numberOfJobHistory(state: ConfigState): number {
    return state.numberOfJobHistory;
  },
};

export default getters;
