
import { Component, Vue, Prop } from 'vue-property-decorator';
import ButtonGroupField from '@/components/ButtonGroupField.vue';
import { Gender } from '@/interfaces/enums';

@Component({
  components: {
  ButtonGroupField,
  },
  })
export default class GenderField extends Vue {
  @Prop() gender!: Gender;

  genders = [
    {
      value: Gender.Female,
      label: 'Female'
    },
    {
      value: Gender.Male,
      label: 'Male'
    }
  ];
}
