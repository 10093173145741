
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import Title1 from '@/components/Title1.vue';
import SelectField from '@/components/SelectField.vue';
import Title2 from '@/components/Title2.vue';
import TextField from '@/components/TextField.vue';
import Explanation from '@/components/Explanation.vue';
import { LabelledValue } from '@/interfaces/utils';
import { Trajectory } from '@/interfaces/card';
import { JobHistory } from '@/interfaces/card';
import { ElementFE } from '../../../interfaces/utils';

const mainModule = namespace('main');
const configModule = namespace('config');

@Component({
  components: {
  Title1,
  SelectField,
  Title2,
  TextField,
  Explanation
  },
  })
export default class TrajectorySection extends Vue {
  @Prop()trajectory!: Trajectory;
  @mainModule.Getter('countries') countries!: LabelledValue[];
  @mainModule.Getter('languages') languages!: LabelledValue[];
  @configModule.Getter('mandatoryFields') mandatoryFields!: ElementFE[];

  jobHistoryExplanation = '<div class="textarea-explanation">Share up to three previous roles/companies which constitute notable ' +
                'experiences and career trajectory for this individual. Simply note the role title and the company.</div>';

  getJobHistoryLabel(mainLabel: string, key: 'role' | 'company', index: number){
    if(index === 0) {
      return this.jobHistoryFieldsAreNotCompleted(key) ?
        `${mainLabel} <span class="red--text"> *</span> </p>` :
        `${mainLabel}<span> *</span></p>`;
    }
    return '';
  }

  jobHistoryFieldsAreNotCompleted(key: 'role' | 'company') {
    const jobHistoryEmptyMandatoryFields = this.mandatoryFields
      .filter((item: ElementFE) => {
        return _.startsWith(item.key, 'trajectory.jobHistory') &&
        _.endsWith(item.key, `.${key}`) &&
        _.get(this, item.key) === '';
      });
    return jobHistoryEmptyMandatoryFields.length > 0;
  }

}
