
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const mainModule = namespace('main');

@Component(
  {
    components: {}
  }
)
export default class ErrorAlert extends Vue {
  @mainModule.Getter('error') error!: Error | null;
  @mainModule.Action('removeError') removeError!: any;

  get hasError() {
    return this.error !== null;
  }

}
