
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Title1 from '@/components/Title1.vue';
import TextareaField from '@/components/TextareaField.vue';
import SelectField from '@/components/SelectField.vue';
import ButtonGroupField from '@/components/ButtonGroupField.vue';
import NudgeBox from '@/components/NudgeBox.vue';
import { ManagementScope, StrongestAttributes } from '@/interfaces/enums';
import { Performance } from '@/interfaces/card';
import { fieldRules } from '@/utils';

const nudgeModule = namespace('nudge');

@Component({
  components: {
  Title1,
  TextareaField,
  SelectField,
  ButtonGroupField,
  NudgeBox
  },
  })
export default class PerformanceSection extends Vue {
  @Prop() performance!: Performance;
  @Prop() name!: string;
  @Prop() surname!: string;

  @nudgeModule.Getter('nudge_box1') nudge_box1_vue!: string;

  validatorRules = [ fieldRules.containsName(this.name), fieldRules.containsSurname(this.surname) ];

  fields = [
    {
      title: 'NOTABLE IMPACT AND RESULTS OVER THE LAST FEW YEARS',
      explanation: '<div class="textarea-explanation">Provide 2-3 examples of accomplishments of this talent\'s accomplishments'+
        ' - individually or through a team.</div><div class="nudge"><h3>BE MINDFUL</h3><ul><li>Consider key achievements, not '+
        ' just the most recent or the one that springs to mind</li><li>Be specific on the WHAT (outcome/ results)'+
        ' and the HOW (qualities that helped drive results)</li><li>Avoid describing the working style/ personality more'+
        ' than the actual impact</li><li>Write in a way that shows the talent\'s genuine personal agency, drive and leadership'+
        ' </li></ul></div>',
      rules: [...this.validatorRules, fieldRules.isValidForExport(480, 9, 70)],
      maxLines: 9,
      maxCharacters: 480,
      key: 'results',
      id:'notable-impact-and-results',
    },
    {
      title: 'What springs to mind when thinking about what is unique about this individual?',
      explanation: '',
      rules: [...this.validatorRules, fieldRules.isValidForExport(130, 3, 70)],
      maxLines: 3,
      maxCharacters: 130,
      key:'knownFor',
      id: 'known-for'
    },
    {
      title: 'WHEN IS THIS PERSON AT THEIR BEST?',
      explanation: 'Describe the conditions in which this talent is at their best: types of roles, ' +
        'responsibilities, tasks, or teams that bring out what they are good at, energised by, and contribute ' +
        'significantly to collective performance.',
      rules: [...this.validatorRules, fieldRules.isValidForExport(130, 3, 70)],
      maxLines: 3,
      maxCharacters: 130,
      key: 'timeAtTheBest',
      id: 'time-at-the-best'
    },
  ];

  capabilitiesField = {
    title: 'Most valuable skills & capabilities',
    explanation: 'Please describe this talent\'s most valuable skills and capabilities,  either prominent skills or ' +
      'unique/hard to fill.',
    rules: [...this.validatorRules, fieldRules.isValidForExport(130, 3, 70)],
    maxLines: 3,
    maxCharacters: 130,
    id: 'skills-capabilities'
  };

  attributes = [
    {
      label: 'Agile',
      value: StrongestAttributes.Agile,
    },
    {
      label: 'Creative',
      value: StrongestAttributes.Creative,
    },
    {
      label: 'Comfortable with complexity and ambiguity',
      value: StrongestAttributes.ComfortableWithComplexityAndAmbiguity,
    },
    {
      label: 'Curious',
      value: StrongestAttributes.Curious,
    },
    {
      label: 'Developing people',
      value: StrongestAttributes.DevelopingPeople,
    },
    {
      label: 'Innovative',
      value: StrongestAttributes.Innovative,
    },
    {
      label: 'Resilient',
      value: StrongestAttributes.Resilient,
    },

    {
      label: 'Aesthetically inclined',
      value: StrongestAttributes.AestheticallyInclined,
    },
    {
      label: 'Driven',
      value: StrongestAttributes.Driven,
    },
    {
      label: 'Humble',
      value: StrongestAttributes.Humble,
    },
    {
      label: 'Cultured',
      value: StrongestAttributes.Cultured,
    },
    {
      label: 'Decisive',
      value: StrongestAttributes.Decisive,
    },
    {
      label: 'Trustworthy with integrity',
      value: StrongestAttributes.Trustworthy,
    },
    {
      label: 'Transformational',
      value: StrongestAttributes.Transformational,
    },
    {
      label: 'Aware about the world around them',
      value: StrongestAttributes.AwareAboutTheWorldAroundThem,
    },
    {
      label: 'Empowering people',
      value: StrongestAttributes.EmpoweringPeople,
    },
    {
      label: 'Culturally sensitive',
      value: StrongestAttributes.CulturallySensitive,
    },
    {
      label: 'Knowledgeable about the brand',
      value: StrongestAttributes.KnowledgeAboutTheBrand,
    },
    {
      label: 'Self-Aware',
      value: StrongestAttributes.SelfAware,
    },
    {
      label: 'Visionary',
      value: StrongestAttributes.Visionary,
    },
    {
      label: 'Strategic Thinker',
      value: StrongestAttributes.StrategicThinker,
    },
    {
      label: 'Collaborative',
      value: StrongestAttributes.Collaborative,
    },
    {
      label: 'Performance Minded',
      value: StrongestAttributes.PerformanceMinded,
    },
    {
      label: 'Relationship Focused',
      value: StrongestAttributes.RelationshipFocused,
    },

  ];

  managementScope = [
    {
      label: 'Manages self',
      value: ManagementScope.ManagesSelf,
    },
    {
      label: 'Manages projects',
      value: ManagementScope.ManagesProjects,
    },
    {
      label: 'Manages a team',
      value: ManagementScope.ManagesTeam,
    },
    {
      label: 'Manages other managers',
      value: ManagementScope.ManagesManagers,
    },
  ];

  get sortedAttributes() {
    return this.attributes.sort(function(a, b) {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase(); // ignore upper and lowercase
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  }
}
