import { Module } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { AuthState } from './state';
import { State } from '../state';

const defaultState: AuthState = {
  isLoggedIn: null,
  token: '',
  logInError: false,
  userProfile: null,
};

const authModule: Module<AuthState, State> = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default authModule;
