import Vue from 'vue';
import { AuthState } from './state';
import { User } from '@/interfaces/user';

const mutations = {
  setToken(state: AuthState, payload: string) {
    Vue.set(state, 'token', payload);
  },
  setLoggedIn(state: AuthState, payload: boolean) {
    Vue.set(state, 'isLoggedIn', payload);
  },
  setLogInError(state: AuthState, payload: boolean) {
    Vue.set(state, 'logInError', payload);
  },
  setUserProfile(state: AuthState, payload: User) {
    Vue.set(state, 'userProfile', payload);
  },
};

export default mutations;
