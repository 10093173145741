import { Module } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { NudgeState } from './state';
import { State } from '../state';

const defaultState: NudgeState = {
  nudges: null
};

const nudgeModule: Module<NudgeState, State> = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default nudgeModule;
