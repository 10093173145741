
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Title1 from '@/components/Title1.vue';
import SelectField from '@/components/SelectField.vue';
import Explanation from '@/components/Explanation.vue';
import NudgeBox from '@/components/NudgeBox.vue';
import { Score } from '@/interfaces/enums';
import { CapacityToGrow } from '@/interfaces/card';

const nudgeModule = namespace('nudge');

@Component({
  components: {
  Title1,
  SelectField,
  Explanation,
  NudgeBox,
  },
  })
export default class CapacityToGrowSection extends Vue {
  @Prop() capacityToGrow!: CapacityToGrow;
  @nudgeModule.Getter('nudge_box5') nudge_box5_vue!: string;


  score = [
    {
      label: 'Low',
      value: Score.Low,
    },
    {
      label: 'Average',
      value: Score.Average,
    },
    {
      label: 'Strong',
      value: Score.Strong,
    },
    {
      label: 'Outstanding',
      value: Score.Outstanding,
    },
  ];
}
