
import { Component, Vue, Prop } from 'vue-property-decorator';
import Title2 from '@/components/Title2.vue';
import ButtonGroupField from '@/components/ButtonGroupField.vue';
import SelectField from '@/components/SelectField.vue';
import { WhoIsFilling } from '@/interfaces/enums';


@Component({
  components: {
  Title2,
  SelectField,
  },
  })
export default class WhoIsFillingForm extends Vue {
  @Prop() who!: WhoIsFilling;

  whoLIst = [
    {
      label: 'HR Partner',
      value: WhoIsFilling.HrPartner,
    },
    {
      label: 'Line Manager',
      value: WhoIsFilling.LineManager,
    },
    {
      label: 'Talent Lead',
      value: WhoIsFilling.TalentLead,
    },
  ];

  updateWho(newValue: WhoIsFilling) {
    this.$emit('update:who', newValue);
    this.$emit('handleSave');
  }

  get title(){
    const text = '<p>the primary writer of the talent card?';
    return this.who === null ? `${text} <span class="red--text"> *</span> </p>` : `${text}<span> *</span></p>`;
  }
}
