
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import Banner from '@/components/Banner.vue';
import AnalysisList from '@/views/analysis/AnalysisList.vue';
import { Analysis } from '@/interfaces/analysis';
import TextField from '@/components/TextField.vue';
import CreateAnalysisDialog from '@/views/analysis/CreateAnalysisDialog.vue';

const analysisModule = namespace('analysis');
const authModule = namespace('auth');

@Component({
  components: {
    Banner,
    AnalysisList,
    TextField,
    CreateAnalysisDialog,
  },
})
export default class Admin extends Vue {
  @analysisModule.Getter('allAnalyses') allAnalyses!: Analysis[];
  @analysisModule.Action('getAnalyses') getAnalyses: any;
  @authModule.Getter('isAdmin') isAdmin!: boolean;

  searchValue = '';
  createAnalysisDialog = false;

  created() {
    this.getAnalyses();
  }

  get filteredAnalyses(): Analysis[] {
    return this.allAnalyses.filter((analyse) => {
      return _.includes(analyse.name.toLowerCase(), this.searchValue.toLowerCase());
    });
  }

  searchChange(newValue: string) {
    this.searchValue = newValue;
  }

  openCreateAnalysesDialog() {
    this.createAnalysisDialog = true;
  }
}
