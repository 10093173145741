var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"button-group-field",class:{'button-group-big' : _vm.big}},[(_vm.label)?_c('label',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{class:{'red--text': _vm.isEmpty}},[_vm._v(" * ")]):_vm._e()]):_vm._e(),(_vm.explanation)?_c('p',{staticClass:"button-group-explanation text-center font-italic",domProps:{"innerHTML":_vm._s(_vm.explanation)}}):_vm._e(),_c('v-btn-toggle',{staticClass:"button-group",attrs:{"value":_vm.selection,"multiple":_vm.multiple,"tile":""},on:{"change":function($event){return _vm.handleChange($event)}}},[(!_vm.big)?_c('v-row',_vm._l((_vm.items),function(item,index){return _c('v-col',{key:item.value,class:{
          'pl-1 pl-sm-3' : index === (_vm.items.length-1) && !_vm.smMultiLine,
          'pr⁻1 pr-sm-3': index === 0 && !_vm.smMultiLine,
          'px-sm-3 px-1' : index !== (_vm.items.length-1) && index !== 0 && !_vm.smMultiLine,
          'px-1 pl-sm-3 mt-1' : index === (_vm.items.length-1) && _vm.smMultiLine,
          'px-1 pr-sm-3 mt-1': index === 0 && _vm.smMultiLine,
          'px-sm-3 px-1 mt-1' : index !== (_vm.items.length-1) && index !== 0 && _vm.smMultiLine,
        }},[_c('v-btn',{staticClass:"item-button",attrs:{"disabled":_vm.disabled,"active-class":"is-active","value":item.value,"tile":"","depressed":"","block":""}},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1):_c('v-row',_vm._l((_vm.items),function(item){return _c('v-col',{key:item.value,staticClass:"mt-1 px-1",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"item-button",attrs:{"active-class":"is-active","value":item.value,"tile":"","depressed":"","block":""}},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1)],1),(_vm.rules)?_c('p',{staticClass:"rules"},[_vm._v(_vm._s(_vm.rules))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }