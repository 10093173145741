
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import TextField from '@/components/TextField.vue';
import SelectField from '@/components/SelectField.vue';
import Title2 from '@/components/Title2.vue';
import { AnalysisStatus } from '@/interfaces/enums';
import { fieldRules } from '@/utils';
import { LabelledValue } from '@/interfaces/utils';
import { Analysis } from '@/interfaces/analysis';
import { Project } from '@/interfaces/projects';

const analysisModule = namespace('analysis');
const projectsModule = namespace('projects');

@Component(
  {
    components: {
      SelectField,
      TextField,
      Title2,
    }
  }
)
export default class CreateAnalysisDialog extends Vue {
  @Prop() createAnalysisDialog!: boolean;
  @Prop() projectMailto!: any;

  @analysisModule.Action('createAnalysis') createAnalysis: any;
  @analysisModule.Getter('allAnalyses') allAnalyses!: Analysis[];
  @projectsModule.Getter('allProjects') allProjects!: Project[];
  @projectsModule.Action('getProjects') getProjects: any;

  analysis: Analysis | null = null;

  nameValidatorRules = [fieldRules.minLength(1)];

  created() {
    this.initDialog();
  }

  @Watch('createAnalysisDialog')
  async initDialog(): Promise<void>{
    if(this.createAnalysisDialog) {
      await this.getProjects();
      this.analysis = {name: '', projectId: this.projectMailto? this.projectMailto.id: ''};
    }
  }

  async createAnalyses() {
    const newAnalyse = {
      name: this.analysis!.name,
      projectId: this.analysis!.projectId,
      status: AnalysisStatus.Requested,
    };
    const analyse = await this.createAnalysis(newAnalyse);
    this.$emit('closeDialog');
  }


  @Watch('createAnalysisDialog')
  openCreateAnalysisDialog() {
    this.analysis = {
      name: '',
      projectId: '',
    };
  }

  get isValidForCreation() {
    return  !_.isEmpty(this.analysis!.name) && !_.isEmpty(this.analysis!.projectId);
  }

  get projectsList(): LabelledValue[] {
    return _.sortBy(this.allProjects
      .map((project) => {
        return {label: project.name, value: project.id} as LabelledValue;
      }),
      'label');
  }

  getMailto(projectMailto: any) {
    return ` mailto:analysis@ellpha.com?` +
    `subject=Request%20for%20Project%20${ projectMailto.name }%20Bias%20analysis&` +
    `body=I%20am%20requesting%20a%20bespoke%20analysis%20for%20project%20${ projectMailto.name }%20(id%3A%20${ projectMailto.id }).`+
    `%0A%0AI%20have%20checked%20that%20all%20the%20talent%20cards%20in%20this%20project%20have%20all%20been`+
    `%20Submitted.%0A%0AI%20understand%20that%3A%0A%20%20%20%20%20%20%20%20%0A%20%20%20%20%20%20-%20Ellpha`+
    `%20cannot%20run%20an%20analysis%20on%20incomplete%20talent%20cards%20%20%20%20%0A%20%20%20%20%20%20-%`+
    `20I%20will%20receive%20the%20results%20in%203%20weeks%20maximum.%20%20%20%20%20%20%20%0A%20%20%20%20%20%`+
    `20-%20I%20understand%20that%20by%20requesting%20this%20analysis%2C%20I%20am%20transferring%20personal%20data%`+
    `20that%20have%20been%20anonymised%20from%20Chanel%20talents%20to%20Ellpha%20and%20that%20thereby%20I%20have%`+
    `20the%20consent%20from%20those%20talents%20to%20do%20so.%0A%0APlease%20kindly%20copy%20in%20dl-s-globalpeopledevelopment`+
    `%40chanel.com%20before%20sending.`;
  }
}
