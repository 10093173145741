import { TalentCardsState } from './state';
import { SimplyCard, Card, Manager, Picture } from '@/interfaces/card';
import Vue from 'vue';
import * as _ from 'lodash';

const mutations = {
  setTalentCards(state: TalentCardsState, payload: SimplyCard[]) {
    Vue.set(state, 'cards', payload);
  },
  addNewTalentCardToList(state: TalentCardsState, payload: SimplyCard) {
    state.cards.push(payload)
  },
  setTalentCard(state: TalentCardsState, payload: Card) {
    Vue.set(state, 'currentCard', payload);
  },
  setManagers(state: TalentCardsState, payload: Manager[]) {
    Vue.set(state, 'managers', payload);
  },
  removeNotification(state: TalentCardsState) {
    Vue.set(state, 'notification', null);
  },
  setTalentCardPicture(state: TalentCardsState, file: Picture) {
    if (state.currentCard) {
      Vue.set(state.currentCard, 'picture', file);
    }
  },
  removeTalentCardPicture(state: TalentCardsState) {
    if (state.currentCard) {
      Vue.set(state.currentCard.picture, 'filename', '');
      Vue.set(state.currentCard.picture, 'contentType', '');
    }
  },
  deleteTalentCard(state: TalentCardsState, id: string) {
    const index = _.findIndex(state.cards, { id: id });
    Vue.delete(state.cards, index);
  },
  setExportTcStatus(state: TalentCardsState, isInProgress: boolean) {
    Vue.set(state, "exportTcInProgress", isInProgress);
  },
};

export default mutations;
