import { Module } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { TalentCardsState } from './state';
import { State } from '../state';

const defaultState: TalentCardsState = {
  cards: [],
  currentCard: null,
  managers: [],
  exportTcInProgress: false,
};

const talentCardsModule: Module<TalentCardsState, State> = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default talentCardsModule;
