
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Analysis } from '@/interfaces/analysis';
import { AnalysisStatus } from '@/interfaces/enums';
import { namespace } from 'vuex-class';
import { Project } from '@/interfaces/projects';
import { ExportFormat } from '@/interfaces/enums';
import { ExportConfig } from '@/interfaces/export';
import UploadPdfReportDialog from './UploadPdfReportDialog.vue';
import UploadExcelReportDialog from './UploadExcelReportDialog.vue';

const analysisModule = namespace('analysis');
const authModule = namespace('auth');


@Component({
  components: {
    UploadExcelReportDialog,
    UploadPdfReportDialog,
  }
})
export default class AnalysisList extends Vue {
  @Prop() analyses!: Analysis[];


  @analysisModule.Action('downloadData') downloadData!: (options: ExportConfig) => Promise<void>;
  @analysisModule.Action('downloadReportsExcel') downloadReportsExcel: any;
  @analysisModule.Action('downloadReportsPDF') downloadReportsPDF: any;
  @authModule.Getter('isAnalyst') isAnalyst!: boolean;

  statusLabel = {
    [AnalysisStatus.Done] : 'Done',
    [AnalysisStatus.Requested] : 'Requested',
    [AnalysisStatus.Doing] : 'In Progress',
  }

  dataToUpload: Project | null = null;
  uploadDataConfirmation = false;
  formatToUpload: ExportFormat | null = null;

  
  isDone(status: AnalysisStatus) {
    return status === AnalysisStatus.Done;
  }

  isInProgress(status: AnalysisStatus) {
    return status === AnalysisStatus.Doing;
  }

  isRequested(status: AnalysisStatus) {
    return status === AnalysisStatus.Requested;
  }

  async downloadDataButton(id: string, projectId: string, projectName: string): Promise<void> {
     await this.downloadData({filename: 'all talent cards of ' + projectName,
                        filters:{project_id: projectId },
                        id: id});
  }

  downloadReportsButtonExcel(id: string, analysisName: string) {
     this.downloadReportsExcel({id: id,
                                filename: `report_${analysisName}`});
  }

  downloadReportsButtonPDF(id: string, analysisName: string) {
     this.downloadReportsPDF({id: id,
                              filename: `report_${analysisName}`});
  }

  openAnalysis(id: string) {
    this.$router.push({ name: 'analysisPage', params: { id: id } });
  }
}

