import * as _ from 'lodash';
import { MainState } from './state';
import { LabelledValue } from '@/interfaces/utils';
import { SuccessNotification } from '@/interfaces/enums';

const getters = {
  nationalities(state: MainState): LabelledValue[] {
    return _.orderBy(state.nationalities, ['label'], ['asc']);
  },
  countries(state: MainState): LabelledValue[] {
    return _.orderBy(state.countries, ['label'], ['asc']);
  },
  languages(state: MainState): LabelledValue[] {
    return state.languages;
  },
  error(state: MainState) : Error | null {
    return state.error;
  },
  successNotification(state: MainState) : SuccessNotification | null {
    return state.successNotification;
  }
};

export default getters;
