import { ActionContext } from 'vuex';
import { State } from '../state';
import { ProjectsState } from './state';
import api from '@/api';
import { Project, EditedProject } from '@/interfaces/projects';

type ProjectsContext = ActionContext<ProjectsState, State>;

const actions = {
  async getProjects(context: ProjectsContext) {
    try {
      const data = await api.getProjects();
      if (data) {
        context.commit('setProjects', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async getProject(context: ProjectsContext, projectId: string) {
    try {
      const data = await api.getProject(projectId);
      if (data) {
        context.commit('setProject', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async createProject(context: ProjectsContext, project: Project) {
    try {
      const data = await api.createProject(project);
      if (data) {
        context.commit('addNewProjectToList', data);
        return data;
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async removeProject(context: ProjectsContext, projectId: string) {
    try {
      const data = await api.deleteProject(projectId);
      context.commit('removeProject', projectId);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async updateProject(context: ProjectsContext, project: EditedProject) {
    try {
      const data = await api.updateProject(project);
      context.commit('updateProject', data);
      return data;
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  removeProjectEdition(context: ProjectsContext) {
    context.commit('removeProjectEdition');
  },

};

export default actions;
