import { ConfigState } from '@/store/config/state';
import Vue from 'vue';

const mutations = {
  setApplicationConfig(state: ConfigState, payload: Record<string, any>) {
    Vue.set(state, 'application', payload);
  },
};

export default mutations;
