
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SimplyCard } from '@/interfaces/card';
import * as _ from 'lodash';
import { Status, HomePageFilterType, Accessibility, ExportFormat } from '@/interfaces/enums';
import { User } from '@/interfaces/user';
import { Project } from '@/interfaces/projects';
import { ExportConfig } from '@/interfaces/export';

const authModule = namespace('auth');
const talentCardsModule = namespace('talentCards');

@Component({
  components: {
  },
})
export default class Cards extends Vue {
  @Prop() cards!: SimplyCard[];
  @Prop() filterType!: HomePageFilterType;
  @Prop() project?: Project;
  @Prop() sortBy!: string;

  @authModule.Getter('isCorporate') isCorporate!: boolean;
  @authModule.Getter('isManager') isManager!: boolean;
  @authModule.Getter('currentUser') currentUser!: User;
  @authModule.Getter('isAdmin') isAdmin!: boolean;
  @talentCardsModule.Action('exportOneTalentCard') exportOneTalentCard: any;
  @talentCardsModule.Action('exportTalentCardsInPowerpoint') exportTalentCardsInPowerpoint: any;
  @talentCardsModule.Action('exportTalentCardsInExcel') exportTalentCardsInExcel: any;
  @talentCardsModule.Action('removeTalentCard') removeTalentCard : any;

  statusLabel = {
    [Status.Saved] : 'saved as draft',
    [Status.Submitted] : 'submitted',
  }

  filterTypes = HomePageFilterType;
  status = Status;
  deleteConfirmation = false;
  cardToDelete: SimplyCard | undefined = undefined;
  talentCardToExport: SimplyCard | null = null;
  singleExportConfirmation = false;
  multipleExportConfig: ExportConfig | null = null;
  multipleExportConfirmation = false;
  formatToExport: ExportFormat | null = null;

  isDraft(status: Status) {
    return status === Status.Saved;
  }

  isSubmitted(status: Status) {
    return status === Status.Submitted;
  }

  isPrivate(card: SimplyCard): boolean {
    return card.accessibility === Accessibility.Private;
  }

  closeConfirmationModal() {
    this.deleteConfirmation = false;
    this.cardToDelete = undefined;
  }

  deleteTalentCard() {
    this.removeTalentCard(this.cardToDelete);
    this.closeConfirmationModal();
  }

  openConfirmationModal(card: SimplyCard) {
    this.deleteConfirmation = true;
    this.cardToDelete = card;
  }

  get sortedCards() {
    if(this.sortBy === 'creationDate') {
      return  _.sortBy( this.cards, ['creationDate']);
    }
    return _.sortBy( this.cards, [(c) => (c.name.toLowerCase()), (c) => (c.surname.toLowerCase())]);
  }

  exportTalentCard() {
    this.exportOneTalentCard(this.talentCardToExport);
    this.closeSingleExportConfirmation();
  }

  openSingleExportConfirmation(card: SimplyCard, exportFormat: ExportFormat) {
    this.talentCardToExport = card;
    this.singleExportConfirmation = true;
    this.formatToExport = exportFormat;
  }

  closeSingleExportConfirmation() {
    this.talentCardToExport = null;
    this.singleExportConfirmation = false;
    this.formatToExport = null;
  }

  exportGroupOfTalentCards() {
    if(this.formatToExport === ExportFormat.Powerpoint) {
      this.exportTalentCardsInPowerpoint(this.multipleExportConfig);
    } else {
      this.exportTalentCardsInExcel(this.multipleExportConfig);
    }

    this.closeMultipleExportConfirmation();
  }

  openMultipleExportConfirmation(config: ExportConfig, exportFormat: ExportFormat) {
    this.multipleExportConfig= config;
    this.multipleExportConfirmation = true;
    this.formatToExport = exportFormat;
  }

  closeMultipleExportConfirmation() {
    this.multipleExportConfig = null;
    this.multipleExportConfirmation = false;
    this.formatToExport = null;
  }

  get isExportOfProject(): boolean {
    return this.multipleExportConfig === null? false : this.multipleExportConfig.filters.project_id;
  }

  get exportPowerpoint(): ExportFormat{
    return ExportFormat.Powerpoint;
  }

  get exportExcel(): ExportFormat{
    return ExportFormat.Excel;
  }
}
