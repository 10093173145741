
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import TextField from '@/components/TextField.vue';
import Title2 from '@/components/Title2.vue';
import { Status } from '@/interfaces/enums';
import { fieldRules } from '@/utils';

const talentCardsModule = namespace('talentCards');
const configModule = namespace('config');

@Component(
  {
    components: {
      TextField,
      Title2,
    }
  }
)
export default class CreateTalentCardDialog extends Vue {
  @Prop() createTcDialog!: boolean;

  @talentCardsModule.Action('createTalentCard') createTalentCard: any;
  @configModule.Getter('numberOfJobHistory') numberOfJobHistory!: number;

  partialCard = {name: '', surname: ''};

  async createTc() {
    const jobHistory = [];
    while (jobHistory.length < this.numberOfJobHistory) {
      jobHistory.push({role: '', company: ''});
    }
    const newCard = {
      name: this.partialCard.name,
      surname: this.partialCard.surname,
      businessTitle: '',
      status: Status.Saved,
      userEmail: '',
      gender: null,
      nationality: [],
      residenceCountry: null,
      timeInRole: null,
      timeAtCompany: null,
      entity: null,
      grade: null,
      roleType: null,
      lineManager: {
        surname: '',
        name: '',
        gender: null,
        nationality: [],
        suggestion: false,
      },
      hrManager: {
        surname: '',
        name: '',
        gender: null,
        nationality: [],
        suggestion: false,
      },
      userPosition: null,
      trajectory: {
        jobHistory: jobHistory,
        geographiesLived: [],
        languages: [],
      },
      performance: {
        results: '',
        knownFor:	'',
        timeAtTheBest: '',
        strongestAttributes: [],
        managementScope: null,
        skillsCapacities: '',
      },
      futureDevelopment: {
        futureRoles: '',
        nextRole: '',
        experienceNeeded: '',
        nextFocus: '',
      },
      internationalMobility: {
        openessRelocation: [],
        openessAssignment: [],
      },
      capacityToGrow: {
        learningAgility: null,
        growthDrive: null,
        emotionalAwareness: null,
        promotability: null,
        versatility: null,
      },
      talentGroup: null,
    };
    const card = await this.createTalentCard(newCard);
    this.$emit('closeDialog');
    this.$router.push({ name: 'cardForm', params: { id: card.id } });
  }

  nameValidatorRules = [fieldRules.minLength(1)];

  @Watch('createTcDialog')
  openCreateTcDialog() {
    this.partialCard = {
      name: '',
      surname: '',
    };
  }

  get isValidForCreation() {
    return  !_.isEmpty(this.partialCard.name) && !_.isEmpty(this.partialCard.surname);
  }
}
