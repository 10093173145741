import { UsersState } from './state';
import { User, SimplyUser } from '@/interfaces/user';
import Vue from 'vue';
import * as _ from 'lodash';

const mutations = {
  setUsers(state: UsersState, payload: SimplyUser[]) {
    Vue.set(state, 'users', payload);
  },

  setUser(state: UsersState, payload: User) {
    Vue.set(state, 'editedUser', payload);
  },

  removeUserEdition(state: UsersState) {
    Vue.set(state, 'editedUser', null);
  },

  addNewUser(state: UsersState, payload: User) {
    const simplyUser: SimplyUser = {
      name: payload.name,
      username: payload.username,
      surname: payload.surname,
      id: payload.id,
      role: payload.role,
      isActive: payload.isActive,
      email: payload.email,
    };
    state.users.push(simplyUser);
  },

  updateUser(state: UsersState, payload: User) {
    const simplyUser: SimplyUser = {
      name: payload.name,
      surname: payload.surname,
      username: payload.username,
      id: payload.id,
      role: payload.role,
      isActive: payload.isActive,
      email: payload.email,
    };
    const index = _.findIndex(state.users, { id: payload.id });
    state.users.splice(index, 1, simplyUser);
  },

  deleteUser(state: UsersState, id: string) {
    const index = _.findIndex(state.users, { id: id });
    Vue.delete(state.users, index);
  },
};

export default mutations;
