import api from '@/api';
import { ActionContext } from 'vuex';
import { State } from '@/store/state';
import { NudgeState } from '@/store/nudge/state';

type NudgeContext = ActionContext<NudgeState, State>;

const actions = {
  async getNudges(context: NudgeContext) {
    try {
      const data = await api.getNudges();
      if (data) {
        context.commit('setNudges', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
};

export default actions;
