
import { Component, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import ErrorAlert from '@/components/ErrorAlert.vue';

const authModule = namespace('auth');

@Component(
   {
    components: {
      ErrorAlert,
    }
  }
)
export default class Login extends Vue {
  @authModule.Action('actionLogIn') actionLogIn: any;

  @State((state) => state.auth.loginError) loginError!: boolean;

  public email = '';

  public password = '';

  public appName = process.env.VUE_APP_NAME;

  public submit() {
    this.actionLogIn({ username: this.email, password: this.password });
  }
}
