import { ProjectsState } from './state';
import { Project } from '@/interfaces/projects';
import Vue from 'vue';
import * as _ from 'lodash';



const mutations = {
  addNewProjectToList(state: ProjectsState, payload: Project) {
    state.projects.push(payload);
  },
  
  setProjects(state: ProjectsState, payload: Project[]) {
    Vue.set(state, 'projects', payload);
  },

  removeProject(state: ProjectsState, id: string) {
    const index = _.findIndex(state.projects, { id: id });
    Vue.delete(state.projects, index);
  },

  updateProject(state: ProjectsState, payload: Project) {
    const index = _.findIndex(state.projects, { id: payload.id });
    state.projects.splice(index, 1, payload);
  },

  removeProjectEdition(state: ProjectsState) {
    Vue.set(state, 'editedProject', null);
  },

  setProject(state: ProjectsState, payload: Project) {
    Vue.set(state, 'editedProject', payload);
  },

};

export default mutations;
