import { Nudges } from '@/interfaces/nudge';
import { NudgeState } from '@/store/nudge/state';
import Vue from 'vue';

const mutations = {
  setNudges(state: NudgeState, payload: Nudges) {
    Vue.set(state, 'nudges', payload);
  },
};

export default mutations;
