import * as _ from 'lodash';
import { LabelledValue } from './interfaces/utils';
import _default from 'vuex';
import { Card, Manager } from './interfaces/card';
import { ExportFormat, Status } from './interfaces/enums';

// Token
export const getLocalToken = () => localStorage.getItem('token');
export const saveLocalToken = (token: string) => localStorage.setItem('token', token);
export const removeLocalToken = () => localStorage.removeItem('token');

// Role
export const getLocalRole = () => localStorage.getItem('role');
export const saveLocalRole = (role: string) => localStorage.setItem('role', role);
export const removeLocalRole = () => localStorage.removeItem('role');

export const toCamelCaseKey = (object: any) : any => {
  if (object === Object(object) && !Array.isArray(object) && typeof object !== 'function') {
    const convertedObject: any = {};
    _.forIn(object, (value: any, key: string) => {
      convertedObject[_.camelCase(key)] = toCamelCaseKey(value);
    });
    return convertedObject;
  } else if (Array.isArray(object)) {
    return object.map((i) => {
      return toCamelCaseKey(i);
    });
  }
  return object;
}

export const toSnakeCaseKey = (object: any) : any => {
  if (object === Object(object) && !Array.isArray(object) && typeof object !== 'function') {
    const convertedObject: any = {};
    _.forIn(object, (value: any, key: string) => {
      convertedObject[_.snakeCase(key)] = toSnakeCaseKey(value);
    });
    return convertedObject;
  } else if (Array.isArray(object)) {
    return object.map((i) => {
        return toSnakeCaseKey(i);
    });
  }
  return object;
}

export const convertToLabelledValueList = (object: any) : LabelledValue[] => {
  const list: LabelledValue[] = [];
  _.forIn(object, (code, key) => {
    list.push({
      label: key,
      value: code,
    });
  });
  return list;
}

const fieldContainsText = (fieldValue: string, text: string) : boolean => {
  return text ? _.includes(fieldValue.toLocaleLowerCase(), text.toLocaleLowerCase()) : false;
}

export const isValidEmailField = (fieldValue: string) : boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValue);
}

export const isValidForExportField = (value: string, maxLines: number, maxCharByLine: number) : boolean => {
    const lines = value.split(/(?<=\n)|(?<=.{{maxCharByLine}}}(?!\n))/);
    // (?<=\n) : positive lookbehind to match a newline character
    // (?<=.{{maxCharByLine}}}) : positive lookbehind to match a string of maxCharByLine characters
    // (?!\n) : negative lookahead to match a character that is not a newline character
    let totalLines = 0;
    lines.forEach((line) => {
      const nbLines = Math.ceil(line.length / maxCharByLine);
      totalLines += nbLines;
    })
    if (value) return totalLines <= maxLines;
    return true;
}

export const fieldRules = {
  containsName: (name: string) =>{
    return (value: string) => {
      return fieldContainsText(value, name) ? 'Please, don\'t use the talent name in this field': true;
    };
  },
  containsSurname: (surname: string) =>{
    return (value: string) => {
      return fieldContainsText(value, surname) ? 'Please, don\'t use the talent surname in this field': true;
    };
  },
  isValidEmail: (emailValue: string) =>{
    return isValidEmailField(emailValue) ? true : 'Please, use a valid email (e.g. john.smith@ellpha.com)';
  },
  isValidForExport: (maxCharacters: number, maxLines: number, maxCharByLine: number) =>{
    return (value: string) => {
      return isValidForExportField(value, maxLines, maxCharByLine) ?
        true :
        `Please, don\'t exceed ${maxCharacters} characters and ${maxLines} lines`;
    }
  },
  maxLength: (maxLength: number) =>{
    return (value: string) => {
      return value.length < maxLength + 1;
    };
  },
  minLength: (minLength: number) => {
    return (value: string) => {
      return value.length < minLength + 1 ? `This field must contain at least ${minLength + 1} characters` : true;
    };
  },
}

export const downloadFile = (file: BlobPart, filename: string, format: ExportFormat) => {
  const blobType: Record<ExportFormat, string> = {
    [ExportFormat.Powerpoint]: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    [ExportFormat.Excel]: 'application/vnd.ms-excel',
    [ExportFormat.PDF]: 'application/pdf',
  };
  const extension: Record<ExportFormat, string> = {
    [ExportFormat.Powerpoint]: 'pptx',
    [ExportFormat.Excel]: 'xlsx',
    [ExportFormat.PDF]: 'pdf',
  };
  const blob = new Blob([file], {
    type: blobType[format],
  });

  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, `${filename}.${extension[format]}`);
  }else{
    const fileURL = URL.createObjectURL(blob);
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', `${filename}.${extension[format]}`);
    document.body.appendChild(fileLink);
    fileLink.click();
  }
}

export const getExportTcFilename = (filters: any) => {
  const filenames: any = {
    [Status.Saved]: 'all not submitted talent cards',
    [Status.Submitted]: 'alll submitted talent cards',
  };
  if(_.has(filters, 'status')) {
    return filenames[filters.status];
  }

  return 'all talent cards';
}

export const scrollToElt = (eltId: string) => {
  const elmnt = document.getElementById(eltId);
  if(elmnt) {
    window.scrollTo(0, elmnt.offsetTop);
  }
}

