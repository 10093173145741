
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';
import { FieldItem } from '@/interfaces/fields';

@Component
export default class ButtonGroupField extends Vue {
  @Prop() label?: string;
  @Prop() items!: FieldItem[];
  @Prop() selection!: string | string[];
  @Prop() required!: boolean;
  @Prop() smMultiLine!: boolean;
  @Prop() max?: number;
  @Prop() rules?: string;
  @Prop() explanation?: string;
  @Prop() big!: boolean;
  @Prop() multiple!: boolean;
  @Prop() disabled?: boolean;

  get isEmpty() {
    return this.multiple ? this.selection.length === 0 : (this.selection === '' || this.selection === null);
  }

  get cols() {
    return this.smMultiLine ? 12 : false;
  }

  handleChange(newValue: string | string[]) {
    if(_.isArray(newValue) && this.multiple && this.max && newValue.length > this.max) {
      newValue.pop();
    }
    this.$emit('update:selection', newValue)
  }
}
