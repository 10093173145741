import { ActionContext } from 'vuex';
import { State } from '../state';
import { AnalysisState } from './state';
import api from '@/api';
import { Analysis, UploadAnalysis, UpdateStatusAnalysis } from '@/interfaces/analysis';
import { SuccessNotification } from '@/interfaces/enums';
import { AnalysisStatus } from '@/interfaces/enums';
import { ExportConfig } from '@/interfaces/export';

type AnalysisContext = ActionContext<AnalysisState, State>;

const actions = {

  async getAnalyses(context: AnalysisContext) {
    try {
      const data = await api.getAnalyses();
      if (data) {
        context.commit('setAnalyses', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async getAnalysis(context: AnalysisContext, analyseId: string) {
    try {
      const data = await api.getAnalysis(analyseId);
      if (data) {
        context.commit('setAnalysis', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async createAnalysis(context: AnalysisContext, analyse: Analysis) {
    try {
      const data = await api.createAnalysis(analyse);
      context.commit('addNewAnalysis', data);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async importAnalysis(
    context: AnalysisContext,
    uploadInfo: UploadAnalysis
  ): Promise<void> {
    try {
      const data = await api.importAnalysis(uploadInfo);
      if (!data.filename) {
        await context.dispatch('main/setNewError', new Error('The file was not upload.'), { root: true });
      } else {
        await context.dispatch('main/setSuccessNotification', SuccessNotification.AnalysisReportUploaded, { root: true });
        if (uploadInfo.format === 'excel'){
          await context.dispatch('updateAnalysisStatus', { id: uploadInfo.id, status: AnalysisStatus.Done });
        }
        
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, { root: true });
    }
  },

  async updateAnalysisStatus(context: AnalysisContext, updateStatusAnalysis: UpdateStatusAnalysis) {
    try {
      const data = await api.updateAnalysisStatus(updateStatusAnalysis);
      context.commit('updateAnalysis', data);
    } catch (error) {
      await context.dispatch('main/setNewError', error, { root: true });
    }
  },

  async downloadData(
    context: AnalysisContext,
    options: ExportConfig) {
    try {
      context.commit('setDownloadDataAnalysisStatus', true);
      const data = await api.downloadData(options.filters);
      await context.dispatch('checkExportExcelFileAvailability', { id: data.filename, filename: options.filename });
      await context.dispatch('main/setSuccessNotification', SuccessNotification.DownloadData, { root: true });
      if (context.rootGetters['auth/isAnalyst']){
        await context.dispatch('updateAnalysisStatus', { id: options.id, status: AnalysisStatus.Doing });
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, { root: true });
    }
  },

  async checkExportExcelFileAvailability(
    context: AnalysisContext,
    config: { id: string, filename: string }) {
    try {
      const data = await api.checkExportExcelFileAvailability(config.id);
      if (data.detail === 'Pending export still in progress') {
        setTimeout(() => {
          context.dispatch('checkExportExcelFileAvailability', config);
        }, 2000);
      } else {
        await context.dispatch('getExportExcelFile', config);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, { root: true });
    }
  },

  async getExportExcelFile(
    context: AnalysisContext,
    config: { id: string, filename: string }) {
    try {
      await api.exportExcelFile(config.id, config.filename);
      context.commit('setDownloadDataAnalysisStatus', false);
    } catch (error) {
      await context.dispatch('main/setNewError', error, { root: true });
    }
  },

  async downloadReportsExcel(
    context: AnalysisContext,
    config: { id: string, filename: string }) {
    try {
      const data = await api.downloadReportsExcel(config.id, config.filename);
    } catch (error) {
      await context.dispatch('main/setNewError', error, { root: true });
    }
  },

  async downloadReportsPDF(
    context: AnalysisContext,
    config: { id: string, filename: string }) {
    try {
      const data = await api.downloadReportsPDF(config.id, config.filename);
    } catch (error) {
      await context.dispatch('main/setNewError', error, { root: true });
    }
  },
}

export default actions;
