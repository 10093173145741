import { ActionContext } from 'vuex';
import { State } from '../state';
import { UsersState } from './state';
import api from '@/api';
import { User } from '@/interfaces/user';

type UsersContext = ActionContext<UsersState, State>;

const actions = {

  async getUsers(context: UsersContext) {
    try {
      const data = await api.getUsers();
      if (data) {
        context.commit('setUsers', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async getUser(context: UsersContext, userId: string) {
    try {
      const data = await api.getUser(userId);
      if (data) {
        context.commit('setUser', data);
      }
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  removeUserEdition(context: UsersContext) {
    context.commit('removeUserEdition');
  },

  async createUser(context: UsersContext, user: User) {
    try {
      const data = await api.createUser(user);
      context.commit('addNewUser', data);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async updateUser(context: UsersContext, user: User) {
    try {
      const data = await api.updateUser(user);
      context.commit('updateUser', data);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },

  async removeUser(context: UsersContext, id: string) {
    try {
      await api.deleteUser(id);
      context.commit('deleteUser', id);
    } catch (error) {
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
}

export default actions;
