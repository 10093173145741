import { Module } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { MainState } from './state';
import { State } from '../state';

const defaultState: MainState = {
  nationalities: [],
  languages: [],
  countries: [],
  error: null,
  successNotification: null,
};

const mainModule: Module<MainState, State> = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default mainModule;
