
import { Component, Vue, Prop } from 'vue-property-decorator';
import Title1 from '@/components/Title1.vue';
import Explanation from '@/components/Explanation.vue';
import ButtonGroupField from '@/components/ButtonGroupField.vue';
import { TalentGroup } from '@/interfaces/enums';

@Component({
  components: {
  Title1,
  Explanation,
  ButtonGroupField,
  },
  })
export default class TalentGroupForm extends Vue {
  @Prop() talentGroup!: TalentGroup| null;

  talentGroups = [
    {
      label: 'Core Talent',
      value: TalentGroup.CoreTalent,
    },
    {
      label: 'Fast Tracker',
      value: TalentGroup.FastTracker,
    },
    {
      label: 'Expandable Talent',
      value: TalentGroup.ExpandableTalent,
    },
  ];
  explanation = '<div class="textarea-explanation">Select the most appropriate talent segment for this individual: <br>' +
    '(Fast trackers are our leadership pipeline for the future, the ones who can grow in ' +
    'the critical positions for the Brand in short or even mid/long term. <br>' +
    'Expandable talent are people highly valued and with capacity to grow. <br>' +
    'Core talent are good performers with average capacity to grow).</div>';

  updateTalentGroup(newValue: TalentGroup) {
    this.$emit('update:talentGroup', newValue);
    this.$emit('handleSave');
  }

  get title(){
    const text = 'Talent Group ';
    return this.talentGroup === null ? `${text} <span class="red--text"> *</span> </p>` : `${text}<span> *</span></p>`;
  }

}
