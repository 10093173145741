
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';


const talentCardsModule = namespace('talentCards');

@Component(
  {
    components: {}
  }
)
export default class ExportAlert extends Vue {
  @talentCardsModule.Getter('exportTcDone') exportTcDone!: any;

}
