import { Module } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { ProjectsState } from './state';
import { State } from '../state';

const defaultState: ProjectsState = {
  projects: [],
  editedProject: null,
};

const projectsModule: Module<ProjectsState, State> = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default projectsModule;
