import { Module } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import { ConfigState } from './state';
import { State } from '../state';

// this module was created to anticipate the app V2 with a module of the form configuration by the administrators.

const defaultState: ConfigState = {
  application: null,
  mandatoryFields: [
    { key: 'name' , label: 'Name', eltId: 'talent-name'},
    { key: 'surname', label: 'Surname', eltId: 'talent-surname'},
    { key: 'gender', label: 'Gender', eltId: 'talent-gender'},
    { key: 'nationality', label: 'Nationality', eltId: 'talent-nationality'},
    { key: 'residenceCountry', label: 'Country of residence', eltId: 'talent-residence-country'},
    { key: 'businessTitle', label: 'Business title', eltId: 'talent-business-title'},
    { key: 'timeInRole', label: 'Time in role', eltId: 'talent-time-in-role'},
    { key: 'timeAtCompany', label: 'Time at Chanel', eltId: 'talent-time-at-company'},
    { key: 'entity', label: 'Entity', eltId: 'talent-entity'},
    { key: 'grade', label: 'Grade', eltId: 'talent-grade'},
    { key: 'roleType', label: 'Type of role', eltId: 'talent-role'},
    { key: 'lineManager.name', label: 'Name of line manager', eltId: 'about-line-manager-name'},
    { key: 'lineManager.surname', label: 'Surname of line manager', eltId: 'about-line-manager-surname'},
    { key: 'lineManager.gender', label: 'Gender of line manager', eltId: 'about-line-manager-gender'},
    { key: 'lineManager.nationality', label: 'Nationality of line manager', eltId: 'about-line-manager-nationality'},
    { key: 'hrManager.name', label: 'Name of HR patner', eltId: 'about-hr-manager-name'},
    { key: 'hrManager.surname', label: 'Surname of HR patner', eltId: 'about-hr-manager-surname'},
    { key: 'hrManager.gender', label: 'Gender of HR patner', eltId: 'about-hr-manager-gender'},
    { key: 'hrManager.nationality', label: 'Nationality of HR patner', eltId: 'about-hr-manager-nationality'},
    { key: 'userPosition', label: 'Writer of the talent card', eltId: 'who-is-filling'},
    { key: 'trajectory.jobHistory[0].role', label: 'Role title of Job history', eltId: 'job-history-role-0'},
    { key: 'trajectory.jobHistory[0].company', label: 'Company of Job history', eltId: 'job-history-company-0'},
    { key: 'trajectory.geographiesLived', label: 'Countries in wich this individual has lived', eltId: 'geographies-lived'},
    { key: 'trajectory.languages', label: 'Languages this individual speaks', eltId: 'languages'},
    { key: 'performance.results', label: 'Notable impact and results', eltId: 'notable-impact-and-results'},
    { key: 'performance.knownFor', label: 'What is unique about this individual?', eltId: 'known-for'},
    { key: 'performance.timeAtTheBest', label: 'When is this person at their best?', eltId: 'time-at-the-best'},
    { key: 'performance.strongestAttributes', label: 'Most notable leadership attributes', eltId: 'most-notable-attributes'},
    { key: 'performance.managementScope', label: 'Management scope', eltId: 'management-scope'},
    { key: 'performance.skillsCapacities', label: 'Most valuable skills & capabilities', eltId: 'skills-capabilities'},
    { key: 'futureDevelopment.nextRole', label: 'Options for next roles', eltId: 'next-role'},
    { key: 'futureDevelopment.experienceNeeded', label: 'Experience/exposure to be gained', eltId: 'experience-needed'},
    { key: 'futureDevelopment.nextFocus', label: 'Single most important focus area', eltId: 'next-focus'},
    { key: 'internationalMobility.openessRelocation', label: 'Openess to relocation', eltId: 'openess-relocation'},
    { key: 'internationalMobility.openessAssignment', label: 'Openess to short-term assignment', eltId: 'openess-assignment'},
    { key: 'capacityToGrow.learningAgility', label: 'Learning agility', eltId: 'learnig-agility'},
    { key: 'capacityToGrow.growthDrive', label: 'Growth drive', eltId: 'growth-drive'},
    { key: 'capacityToGrow.emotionalAwareness', label: 'Emotional awareness', eltId: 'emotional-awareness'},
    { key: 'capacityToGrow.promotability', label: 'Promotability', eltId: 'promotability'},
    { key: 'capacityToGrow.versatility', label: 'Versatility', eltId: 'versatility'},
    { key: 'talentGroup', label: 'Talent group', eltId: 'talent-group'},
  ],
  numberOfJobHistory: 3,
};

const configModule: Module<ConfigState, State> = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default configModule;
