import { AxiosError } from 'axios';
import { ActionContext } from 'vuex';
import router from '@/router';
import api from '@/api';
import store from '@/store'
import { getLocalToken, removeLocalToken, saveLocalToken, removeLocalRole, saveLocalRole } from '@/utils';
import { State } from '../state';

import { AuthState } from './state';

type AuthContext = ActionContext<AuthState, State>;

const actions = {
  async actionCheckLoggedIn(context: AuthContext) {
    if (context.rootState.config.application.use_sso) {
      try {
        await context.dispatch('actionGetUserProfile');
        context.commit('setLoggedIn', true);
      } catch (error) {
        context.commit('setLoggedIn', false);
      }
    } else {
      if (!context.state.isLoggedIn) {
        let token = null;
        const localToken = getLocalToken();
        if (localToken) {
          context.commit('setToken', localToken);
          token = localToken;
          context.commit('setLoggedIn', true);
        }
        if (token) {
          try {
            await context.dispatch('actionGetUserProfile');
          } catch (error) {
            await context.dispatch('actionRemoveLogIn');
          }
        } else {
          await context.dispatch('actionRemoveLogIn');
        }
      }
    }
  },
  async actionLogIn(context: AuthContext, payload: { username: string; password: string }) {
    try {
      const response = await api.logInGetToken(payload.username, payload.password);
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        context.commit('setToken', token);
        context.commit('setLoggedIn', true);
        context.commit('setLogInError', false);
        await context.dispatch('actionGetUserProfile');
        await context.dispatch('actionRouteLoggedIn');
      } else {
        context.dispatch('actionLogOut');
      }
    } catch (err) {
      context.commit('setLogInError', true);
      context.dispatch('actionLogOut');
      await context.dispatch('main/setNewError', err, {root: true});
    }
  },
  async actionGetUserProfile(context: AuthContext) {
    try {
      const response = await api.getMe();
      if (response) {
        context.commit('setUserProfile', response);
        saveLocalRole(response.role); 
      }
    } catch (error) {
      context.dispatch('actionCheckApiError', error);
      await context.dispatch('main/setNewError', error, {root: true});
    }
  },
  async actionRouteLoggedIn(context: AuthContext) {
    if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
      if (context.getters['isAnalyst']) {
        await router.push('/analysis');
      } else {
        await router.push('/talent/cards');
      }
    }
  },
  actionCheckApiError(context: AuthContext, payload: AxiosError) {
    if (payload.response!.status === 401) {
      context.dispatch('actionLogOut');
    }
  },
  actionLogOut(context: AuthContext) {
    context.dispatch('actionRemoveLogIn');
    context.dispatch('actionRouteLogOut');
  },
  actionRemoveLogIn(context: AuthContext) {
    removeLocalToken();
    removeLocalRole();
    context.commit('setToken', '');
    context.commit('setLoggedIn', false);
  },
  actionRouteLogOut(context: AuthContext) {
    if (store.state.config.application.use_sso) {
      window.location.assign("/oauth2/sign_out?rd=https%3A%2F%2Fcid.clasp-infra.com/adfs/oauth2/logout");
    }
    else if (router.currentRoute.path !== '/login') {
      router.push('/login');
    }
  },
};

export default actions;
