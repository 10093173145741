import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { store } from '@/store';
import vuetify from '@/plugins/vuetify';
import filters from '@/filters';
import '@/plugins/axiosConfig';
import 'babel-polyfill';

Vue.use(filters);

Vue.config.productionTip = false;

store.dispatch('config/actionEnsureApplicationConfig').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});

