
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SelectField from '@/components/SelectField.vue';
import { LabelledValue } from '@/interfaces/utils';

const mainModule = namespace('main');

@Component({
  components: {
  SelectField,
  },
  })
export default class NationalityField extends Vue {
  @Prop() nationalities!: string[];
  @Prop() disabled?: boolean;

  @mainModule.Getter('nationalities') nationalityList!: LabelledValue[];
}
