import { NudgeState } from './state';

const getters = {
  cycle(state: NudgeState): number {
    return state.nudges == undefined ? -1 : state.nudges.cycle;
  },

  nudge_box1(state: NudgeState): string {
    return state.nudges == undefined ? "" : state.nudges.nudgeBox1;
  },
  nudge_box2(state: NudgeState): string {
    return state.nudges == undefined ? "" : state.nudges.nudgeBox2;
  },
  nudge_box3(state: NudgeState): string {
    return state.nudges == undefined ? "" : state.nudges.nudgeBox3;
  },
  nudge_box4(state: NudgeState): string {
    return state.nudges == undefined ? "" : state.nudges.nudgeBox4; 
  },
  nudge_box5(state: NudgeState): string {
    return state.nudges == undefined ? "" : state.nudges.nudgeBox5;
  },

};

export default getters;