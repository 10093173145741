import { TalentCardsState } from './state';
import * as _ from 'lodash';
import { SimplyCard, Card, Manager } from '@/interfaces/card';
import { Status } from '@/interfaces/enums';

const getters = {
  submittedCards(state: TalentCardsState): SimplyCard[] {
    if (state.cards) {
      return state.cards
        .filter(card => card.status === Status.Submitted);
    }
    return [];
  },

  draftCards(state: TalentCardsState): SimplyCard[] {
    if (state.cards) {
      return state.cards
        .filter(card => card.status === Status.Saved);
    }
    return [];
  },

  myCards(state: TalentCardsState, getters: any, rootState: any, rootGetters: any): SimplyCard[] {
    if (state.cards) {
      return state.cards
        .filter(card => card.userId === rootGetters['auth/currentUser'].id);;
    }
    return [];
  },

  allCards(state: TalentCardsState): SimplyCard[] {
    if (state.cards) {
      return state.cards;
    }
    return [];
  },

  currentCard(state: TalentCardsState): Card | null {
    return state.currentCard;
  },

  exportTcDone(state: TalentCardsState): boolean {
    return state.exportTcInProgress;
  },

  managers(state: TalentCardsState): Manager[] {
    const realManager = state.managers.filter((manager) => {
      return !_.isEmpty(manager.name) &&
        !_.isEmpty(manager.surname) &&
        !_.isEmpty(manager.gender) &&
        !_.isEmpty(manager.nationality);
    });
    return _.uniqBy(realManager, _.property(['name', 'surname', 'gender', 'nationality']));
  },
};

export default getters;
