export enum Gender {
  Female = 'female',
  Male = 'male',
}

export enum Score {
  Low = '1',
  Average = '2',
  Strong = '3',
  Outstanding = '4',
}

export enum Openess {
  Anytime = 'anytime',
  InTheFuture = 'inTheFuture',
  DependsRole = 'dependsOnRole',
  DependsLocation = 'dependsOnLocation',
  NotOpen = 'notOpen',
}

export enum TimeInRole {
  '1year' = '1',
  '2years' = '2',
  '3years' = '3',
  '4years' = '4',
  '5years' = '5',
  More = '6+',
}

export enum TimeAtCompany {
  '0-4',
  '5-9',
  '10-14',
  '15-19',
  '20+',
}

export enum Entity {
  Region = 'region',
  Division = 'division',
  Corporate = 'corporate',
}

export enum Grade {
  Grade1 = '<9',
  Grade2 = '9',
  Grade3 = '10',
  Grade4 = '11',
  Grade5 = '12',
  Grade6 = '13',
  Grade7 = '14',
  Grade8 = '15',
  Grade9 = '16',
  Grade10 = '17',
  Grade11 = '18',
}

export enum Role {
  BusinessRole =  'businessRole',
  FunctionalRole = 'functionalRole',
  Other = 'other',
}

export enum TalentGroup {
  CoreTalent = 'coreTalent',
  FastTracker = 'fastTracker',
  ExpandableTalent = 'expandableTalent',
}

export enum WhoIsFilling {
  HrPartner = 'HR',
  LineManager = 'Line',
  TalentLead = 'Talent Lead',
}

export enum ManagementScope {
  ManagesSelf = 'managesSelf',
  ManagesProjects ='managesProjects',
  ManagesTeam = 'managesTeam',
  ManagesManagers = 'managesManagers',
}

export enum StrongestAttributes {
  AwareAboutTheWorldAroundThem = 'awareAboutTheWorldAroundThem',
  Transformational = 'transformational',
  Visionary = 'visionary',
  ComfortableWithComplexityAndAmbiguity = 'comfortableWithComplexityAndAmbiguity',
  Curious = 'curious',
  StrategicThinker = 'strategicThinker',
  DevelopingPeople = 'developingPeople',
  Creative = 'creative',
  Humble = 'humble',
  Resilient = 'resilient',
  AestheticallyInclined = 'aestheticallyInclined',
  Agile = 'agile',
  SelfAware = 'selfAware',
  Collaborative = 'collaborative',
  KnowledgeAboutTheBrand = 'knowledgeableAboutTheBrand',
  EmpoweringPeople = 'empoweringPeople',
  Cultured = 'cultured',
  CulturallySensitive = 'culturallySensitive',
  RelationshipFocused = 'relationshipFocused',
  Innovative = 'innovative',
  PerformanceMinded = 'performanceMinded',
  Decisive = 'decisive',
  Driven = 'driven',
  Trustworthy= 'trustworthyWithIntegrity',
}

export enum Status {
  Saved = 'saved',
  Submitted = 'submitted',
  Validated = 'validated',
}

export enum UserRole {
  Corporate = 'user',
  Manager = 'superuser',
  Admin = 'admin',
  Analyst = 'analyst',
}

export enum SuccessNotification {
  TalentCardSaved = 'talentCardSaved',
  TalentCardSubmitted = 'talentCardSubmitted',
  TalentCardsSaved = 'talentCardsSaved',
  AnalysisReportUploaded = 'analysisReportUploaded',
  DownloadData = 'downloadData',
}

export enum HomePageFilterType {
  All = 'all',
  Submitted = 'submitted',
  My = 'my',
  NotSubmitted = 'notSubmitted',
  Search = 'search',
  Project = 'project',
}

export enum Accessibility {
  Private = 'private',
  Public = 'public',
}

export enum ExportFormat {
  Excel,
  Powerpoint,
  PDF,
}

export enum AnalysisStatus {
  Requested = 'requested',
  Doing = 'doing',
  Done = 'done',
}
