
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';
import { namespace } from 'vuex-class';
import TextField from '@/components/TextField.vue';
import FileField from '@/components/FileField.vue';
import Title2 from '@/components/Title2.vue';
import ButtonGroupField from '@/components/ButtonGroupField.vue';
import SelectField from '@/components/SelectField.vue';
import GenderField from '@/components/GenderField.vue';
import NationalityField from '@/components/NationalityField.vue';
import { TimeInRole, TimeAtCompany, Entity, Grade, Role } from '@/interfaces/enums';
import { LabelledValue } from '@/interfaces/utils';
import { Card } from '@/interfaces/card';
import { Gender } from '@/interfaces/enums';
import { fieldRules } from '@/utils';

const mainModule = namespace('main');
const talentCardsModule = namespace('talentCards');

@Component({
  components: {
  TextField,
  FileField,
  Title2,
  ButtonGroupField,
  SelectField,
  GenderField,
  NationalityField,
  },
  })
export default class AboutTalent extends Vue {
  @mainModule.Getter('countries') countries!: LabelledValue[];
  @talentCardsModule.Action('uploadPicture') uploadPicture: any;
  @talentCardsModule.Action('removePicture') removePicture: any;
  @talentCardsModule.Action('updatePicture') updatePicture: any;
  @talentCardsModule.Getter('currentCard') currentCard!: Card;

  @Prop() card!: Card;

  updateField(key: string, newValue: Gender | string[]) {
    Vue.set(this.card, key, newValue);
    this.$emit('handleSave');
  }

  handleFileChange(file: File | File[]) {
    const picture =  _.isArray(file) ? file[0] : file;
    if (this.card.picture.filename === '') {
      this.uploadPicture({file: picture, id: this.currentCard.id});
    } else {
      this.updatePicture({file: picture, id: this.currentCard.id});
    }
  }

  handleClearFile() {
    if (this.card.picture.filename !== '') {
      this.removePicture(this.currentCard.id);
    }
  }

  get picture() {
    return this.card.picture && this.card.picture.filename !== '' ?
      {name: this.card.picture.filename, type: this.card.picture.contentType, size: 0} :
      undefined;
  }

  timesInRole =  [
    {
      label: '1',
      value: TimeInRole['1year'],
    },
    {
      label: '2',
      value: TimeInRole['2years']
    },
    {
      label: '3',
      value: TimeInRole['3years'],
    },
    {
      label: '4',
      value: TimeInRole['4years'],
    },
    {
      label: '5',
      value: TimeInRole['5years'],
    },
    {
      label: '6+',
      value: TimeInRole.More,
    }
  ];

  timesAtCompany = [
    {
      label: '0-4',
      value: TimeAtCompany[0],
    },
    {
      label: '5-9',
      value: TimeAtCompany[1],
    },
    {
      label: '10-14',
      value: TimeAtCompany[2],
    },
    {
      label: '15-19',
      value: TimeAtCompany[3],
    },
    {
      label: '20+',
      value: TimeAtCompany[4],
    },
  ];

  entities = [
    {
      label: 'Division',
      value: Entity.Division,
    },
    {
      label: 'Region',
      value: Entity.Region,
    },
    {
      label: 'Corporate',
      value: Entity.Corporate,
    },
  ];

  grades = [
    {
      label: '<9',
      value: Grade.Grade1,
    },
    {
      label: '9',
      value: Grade.Grade2,
    },
    {
      label: '10',
      value:Grade.Grade3,
    },
    {
      label: '11',
      value: Grade.Grade4,
    },
    {
      label: '12',
      value: Grade.Grade5,
    },
    {
      label: '13',
      value: Grade.Grade6,
    },
    {
      label: '14',
      value: Grade.Grade7,
    },
    {
      label: '15',
      value: Grade.Grade8
    },
    {
      label: '16',
      value: Grade.Grade9,
    },
    {
      label: '17',
      value: Grade.Grade10,
    },
    {
      label: '18',
      value: Grade.Grade11,
    },
  ];

  roles = [
    {
      label: 'P&L/Business role',
      value: Role.BusinessRole,
    },
    {
      label: 'Corporate/Functional role',
      value: Role.FunctionalRole,
    },
    {
      label: 'Business support/Other',
      value: Role.Other,
    }
  ];

  nameValidatorRules = [fieldRules.minLength(1)];

}
