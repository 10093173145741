
import { Component, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';

const configModule = namespace('config');
const authModule = namespace('auth');

@Component({
  components: {},
})
export default class App extends Vue {
  @configModule.Action('actionEnsureApplicationConfig') actionEnsureApplicationConfig!: () => Promise<void>;
  @authModule.Action('actionCheckLoggedIn') actionCheckLoggedIn!: () => Promise<void>;

  @State((state) => state.auth.isLoggedIn) loggedIn!: boolean | null;

  public async created() {
    await this.actionEnsureApplicationConfig();
    await this.actionCheckLoggedIn();
  }
}
