
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';

@Component
export default class FileField extends Vue {
  @Prop() label?: string;
  @Prop() file?: File;
  @Prop() accept?: string;
  @Prop() required!: boolean;

  maxSizeFile = 5000000;

  rules = [
    (value:  File[] | File | undefined) => {
      const newValue =  _.isArray(value) ? value[0] : value;
      return (!newValue || newValue.size < this.maxSizeFile || 'File size should be less than 5 MB!');
    },
  ];

  handleChange(file: File[] | File | undefined) {
    if(file) {
      const newFile =  _.isArray(file) ? file[0] : file;
      if (newFile.size > 0 && newFile.size < this.maxSizeFile) {
        this.$emit('newFile', newFile);
      }
    }
  }
}
