
import { Component, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import CreateTalentCardDialog from '@/views/talentApp/CreateTalentCardDialog.vue';
import SuccessAlert from '@/components/SuccessAlert.vue';
import ErrorAlert from '@/components/ErrorAlert.vue';
import ExportAlert from '@/components/ExportAlert.vue';
import { User } from '@/interfaces/user';

const authModule = namespace('auth');

@Component(
  {
    components: {
      CreateTalentCardDialog,
      SuccessAlert,
      ErrorAlert,
      ExportAlert,
    }
  }
)
export default class TalentApp extends Vue {
  @authModule.Action('actionLogOut') logOut: any;
  @authModule.Getter('currentUser') user!: User | null;
  @authModule.Getter('isAdmin') isAdmin!: boolean;
  @authModule.Getter('isAnalyst') isAnalyst!: boolean;
  @authModule.Getter('isManager') isManager!: boolean;

  menu = false;
  createTcDialog = false;

  toggleMenu(opened: boolean) {
    this.menu = opened;
  }

  goToTcList() {
    this.menu = false;
    this.$router.push({name: 'cardList'})
  }

  goToChangeOwnership() {
    this.menu = false;
    this.$router.push({name: 'change-ownership'})
  }

  goToAdmin() {
    this.menu = false;
    this.$router.push({name: 'admin'})
  }

  goToAnalysis() {
    this.menu = false;
    this.$router.push({name: 'analysis'})
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  openCreateTcDialog() {
    this.menu = false;
    this.createTcDialog = true;
  }
}
